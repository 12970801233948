import React from "react";
import styled from "styled-components";

import Modal from "@mui/material/Modal";
import { Paper } from "@mui/material";

interface DetailsModalProps {
  handleClose: () => void;
  isOpen: boolean;
  children: React.ReactNode;
}

const CenteredModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;

const PaperContent = styled(Paper)`
  max-width: ${({ theme }) => `${theme.breakpoints.values.xl}px`};
`;

const DetailsModal: (props: DetailsModalProps) => JSX.Element = ({
  handleClose,
  isOpen,
  children,
}) => {
  return (
    <CenteredModal open={isOpen} onClose={handleClose}>
      <PaperContent elevation={3}>{children}</PaperContent>
    </CenteredModal>
  );
};
export default DetailsModal;
