import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
} from "@mui/material";
import { useDropzone, DropzoneOptions } from "react-dropzone";

import ProductService from "../../services/domain/ProductsService";
import useError from "../../hooks/useError";
import useNotification from "../notifications/hook";
import { NotificationTypes } from "../notifications/notification-types";
import { Snapshot } from "../../types/types-models";
import { formatToDateOnly } from "../../lib/helper/date";
import { useHttpErrorReader } from "../../hooks/useHttpErrorReader";

interface ImportModelProps {
  dropzoneOptions?: DropzoneOptions;
  snapshots: Snapshot[];
  onSuccess(): void;
  className?: string;
  platforms: string[];
}
const DropZoneContainer = styled.section`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 200px;
  cursor: pointer;
  border: 1px dashed ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.primary.main}0a;

  > div > p {
    color: ${({ theme }) => theme.palette.primary.main};
    text-align: center;
  }
`;

const Left = styled.div`
  margin-right: auto;
`;

const NoFiles = styled.span`
  color: lightgray;
`;

const ImportModel: (props: ImportModelProps) => JSX.Element = ({
  dropzoneOptions = {},
  snapshots = [],
  onSuccess,
  className,
  platforms = [],
}) => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedSnapshot, setSelectedSnapshot] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [pendingFile, setPendingFile] = useState<File>();
  const acceptedFiles = pendingFile ? [pendingFile] : [];
  const notification = useNotification();
  const { handleErrors } = useError();
  const errorReader = useHttpErrorReader();

  const dropzoneDefaults = {
    multiple: false,
  };

  const { getRootProps, getInputProps } = useDropzone({
    ...dropzoneDefaults,
    ...dropzoneOptions,
    onDrop(files) {
      if (
        files[0]?.type === "application/zip" ||
        files[0]?.type === "application/x-zip-compressed"
      ) {
        setPendingFile(files[0]);
      } else {
        notification.createNotification({
          type: NotificationTypes.Snackbar,
          message: "Only ZIP files are allowed",
          severity: "error",
        });
      }
    },
  });

  const toggleModal = () => setModal(!modal);

  const handleClose = () => {
    setSelectedSnapshot("");
    setSelectedPlatform("");
    setPendingFile(undefined);
    setModal(false);
  };

  const startTraining = () => {
    if (acceptedFiles.length > 0 && acceptedFiles[0]) {
      setLoading(true);
      // https://dev.azure.com/SpectralEngines/SE%20SW/_workitems/edit/3464/
      // Currently models without using white reference are not supported
      // therefore this option should be hidden from application users to avoid confusion.
      const noWhiteReference = false;
      ProductService.sendTrainingRequest(
        selectedSnapshot,
        acceptedFiles[0],
        selectedPlatform,
        noWhiteReference
      )
        .then(() => {
          // Starting was successful
          setLoading(false);
          handleClose();
          notification.createNotification({
            type: NotificationTypes.Snackbar,
            message: "Training started",
            severity: "success",
          });
          if (onSuccess) onSuccess();
        })
        .catch((e) => {
          // Error handling
          setLoading(false);
          if (e.response) {
            const { data: error } = e.response;
            handleErrors({
              error,
              customHandling: errorReader(e.response),
            });
          }
        });
    }
  };

  const trainingDisabled =
    selectedSnapshot === "" ||
    acceptedFiles.length === 0 ||
    selectedPlatform === "" ||
    loading;

  const selectedFile = () =>
    acceptedFiles[0]?.name || <NoFiles>No file selected</NoFiles>;

  return (
    <>
      <Button data-testid="add-model-button" onClick={toggleModal}>
        Upload model package
      </Button>
      <Dialog
        data-testid="import-modal"
        onClose={handleClose}
        open={modal}
        fullWidth
        maxWidth="sm"
        className={className}
      >
        <DialogTitle>Upload model package</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            select
            disabled={snapshots.length === 0}
            helperText={
              snapshots.length === 0 &&
              "Please ensure that a data scientist has created a snapshot for model training. Additionally, if you encounter this issue persistently, it could be due to a technical problem on our end, in which case please contact support."
            }
            label={
              snapshots.length > 0
                ? "Select a snapshot"
                : "No snapshots available"
            }
            onChange={(ev) => {
              const { value } = ev.target;
              setSelectedSnapshot(value as string);
            }}
            value={selectedSnapshot}
          >
            {snapshots.map((snapshot) => (
              <MenuItem key={snapshot.id} value={snapshot.id}>
                {`${formatToDateOnly(snapshot.createdOn)}, V${
                  snapshot.version
                }.0`}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            helperText={
              platforms.length === 0 &&
              "No platforms available. Please try again."
            }
            select
            disabled={platforms.length === 0}
            label={
              platforms.length > 0
                ? "Select a platform"
                : "No platforms available"
            }
            onChange={(ev) => {
              const { value } = ev.target;
              setSelectedPlatform(value as string);
            }}
            value={selectedPlatform}
          >
            {platforms.map((platform) => (
              <MenuItem key={platform} value={platform}>
                {platform}
              </MenuItem>
            ))}
          </TextField>

          <DropZoneContainer>
            <div {...getRootProps({ className: "dropzone" })}>
              <input data-testid="fileUploader" {...getInputProps()} />
              <p>Drop a ZIP file here or click to browse</p>
            </div>
          </DropZoneContainer>
        </DialogContent>
        <DialogActions>
          <Left>{selectedFile()}</Left>
          <Button variant="outlined" onClick={handleClose} disabled={loading}>
            Cancel
          </Button>
          <Button disabled={trainingDisabled} onClick={startTraining}>
            {loading ? <CircularProgress size={15} /> : "Start training"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImportModel;
