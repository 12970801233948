import { useState } from "react";
import {
  ProductService,
  NotificationTypes,
  useNotification,
  PagingData,
  KeyValueParams,
  ProductVariant,
} from "lib-core";

type ProductVariantData = {
  error: unknown | undefined;
  variantsLoading: boolean;
  variantLoading: boolean;
  fetchProductVariantsList(
    pagingData: PagingData,
    metadata: KeyValueParams
  ): void;
  fetchProductVariantById(variantId: number): void;
  productVariantsList: ProductVariant[] | undefined;
  productVariantsTotal: number;
  productVariant: ProductVariant | undefined;
};

const useProductVariant = (): ProductVariantData => {
  const [variantsLoading, setVariantsLoading] = useState<boolean>(false);
  const [variantLoading, setVariantLoading] = useState<boolean>(false);
  const [productVariantsList, setProductVariantsList] =
    useState<ProductVariant[]>();
  const [productVariant, setProductVariant] = useState<ProductVariant>();
  const [error, setError] = useState<unknown>();
  const notification = useNotification();
  const [count, setCount] = useState(0);

  const fetchProductVariantsList = async (
    pagingData: PagingData,
    filters: KeyValueParams
  ) => {
    setVariantsLoading(true);
    setError(undefined);

    try {
      const metadata = (filters || [])
        .filter((p) => p.value !== "")
        .reduce((acc, filter) => {
          const { key } = filter;
          if (key) {
            return { ...acc, [key]: filter.value };
          }
          return acc;
        }, [] as KeyValueParams);

      const fetchedProducts = await ProductService.getProductVariants(
        pagingData,
        metadata
      );

      setProductVariantsList(fetchedProducts.entities);
      setCount(fetchedProducts.paging.total ?? 0);
    } catch (err) {
      setVariantsLoading(false);
      setError(err);
    }
    setVariantsLoading(false);
  };

  const fetchProductVariantById = async (variantId: number) => {
    setVariantLoading(true);
    setError(undefined);
    try {
      const extProductVariant = await ProductService.getProductVariant(
        variantId
      );

      setProductVariant(extProductVariant);
    } catch (err) {
      setVariantLoading(false);
      setError(err);
      const { response } = err as unknown as {
        response: { data: { detail: string } };
      };

      const { detail } = response?.data ?? {
        detail: "Error while fetching product variant information",
      };
      notification.createNotification({
        severity: "error",
        type: NotificationTypes.Snackbar,
        message: detail,
      });
    }
    setVariantLoading(false);
  };

  return {
    variantsLoading,
    variantLoading,
    error,
    fetchProductVariantsList,
    fetchProductVariantById,
    productVariantsList,
    productVariant,
    productVariantsTotal: count,
  };
};

export default useProductVariant;
