import { supportedDeviceMetadata } from "../constants/device";
import { CalibrationType } from "../models/contracts/device/calibration";
import { WhiteReferenceCalibrationResponse } from "../models/contracts/device/dto/calibration";
import { Device } from "../types/contracts/Device";
import { DevicePort } from "../types/types-device";

/**
 * When updating mock data, please scrub any identifiable information,
 * such as email addresses and coordinates.
 */
export const mockDevices: Device[] = [
  {
    serialNumber: "01A3608000A",
    createdOn: "2022-06-21T12:10:56Z",
    modifiedOn: "2022-08-11T08:42:34Z",
    firmwareVersion: null,
    status: "active",
    metadata: {
      lastUsedOn: "2022-08-11T08:42:34.485302Z",
      lastMeasuredBy: "mock-user-1@service-a.test",
      latitude: 52.516280400000001,
      longitude: 13.377701999999999,
    },
    calibrations: {
      "DS2-0-default": {
        whiteReference: {
          validationPass: true,
          validUntil: "2022-09-09T06:50:35Z",
          secondsLeft: 2412057,
        },
        spectralReference: {
          validationPass: null,
          validUntil: "2022-09-11T08:49:37Z",
          secondsLeft: 2592000,
        },
      },
    },
    ver: "549502",
  },
  {
    serialNumber: "12345678912",
    createdOn: "2022-06-20T10:32:02Z",
    modifiedOn: "2022-06-20T10:32:02Z",
    firmwareVersion: "1.1.1",
    status: "active",
    metadata: {
      lastUsedOn: "2022-08-11T08:42:34.485302Z",
      lastMeasuredBy: "mock-user-1@service-a.test",
      latitude: 52.516280400000001,
      longitude: 13.377701999999999,
    },
    calibrations: {
      "DS2-0-default": {
        whiteReference: {
          validationPass: null,
          validUntil: null,
          secondsLeft: -1,
        },
        spectralReference: {
          validationPass: null,
          validUntil: "2022-09-11T08:49:37Z",
          secondsLeft: 2592000,
        },
      },
    },
    ver: "533214",
  },
  {
    serialNumber: "01A35270006",
    createdOn: "2022-06-21T11:54:30Z",
    modifiedOn: "2022-07-07T08:05:39Z",
    firmwareVersion: null,
    status: "active",
    metadata: {
      lastUsedOn: "2022-06-27T07:20:30.560677Z",
      lastMeasuredBy: "mock-user-2@service-b.test",
      latitude: 60.182027200000002,
      longitude: 24.913417111111111,
    },
    calibrations: {
      "DS2-0-default": {
        whiteReference: {
          validationPass: false,
          validUntil: null,
          secondsLeft: -1,
        },
        spectralReference: {
          validationPass: null,
          validUntil: "2022-09-11T08:49:37Z",
          secondsLeft: 2592000,
        },
      },
    },
    ver: "539026",
  },
  {
    serialNumber: "01A35270002",
    createdOn: "2022-06-27T07:35:29Z",
    modifiedOn: "2022-07-29T10:17:18Z",
    firmwareVersion: "1.1.1",
    status: "active",
    metadata: {
      lastUsedOn: "2022-07-29T10:17:18.859971Z",
      lastMeasuredBy: "mock-user-2@service-b.test",
      latitude: 60.182027200000002,
      longitude: 24.913417111111111,
    },
    calibrations: {
      "DS2-0-default": {
        whiteReference: {
          id: "123",
          validationPass: true,
          validUntil: "2022-09-10T10:01:18Z",
          secondsLeft: 2509900,
        },
        spectralReference: {
          validationPass: null,
          validUntil: "2022-09-11T08:49:37Z",
          secondsLeft: 2592000,
        },
      },
    },
    ver: "545754",
  },
  {
    serialNumber: "ABCDEF12345",
    createdOn: "2022-07-01T09:10:33Z",
    modifiedOn: "2022-07-01T09:10:33Z",
    firmwareVersion: null,
    status: "inactive",
    metadata: {
      lastUsedOn: "2022-08-11T08:42:34.485302Z",
      lastMeasuredBy: "mock-user-1@service-a.test",
      latitude: 52.516280400000001,
      longitude: 13.377701999999999,
    },
    calibrations: {
      "DS2-0-default": {
        whiteReference: {
          validationPass: true,
          validUntil: "2022-09-09T06:50:35Z",
          secondsLeft: 2412057,
        },
        spectralReference: {
          validationPass: null,
          validUntil: "2022-09-11T08:49:37Z",
          secondsLeft: 2592000,
        },
      },
    },
    ver: "536836",
  },
  {
    serialNumber: "01A34220009",
    createdOn: "2022-07-01T09:17:09Z",
    modifiedOn: "2022-08-04T14:45:22Z",
    firmwareVersion: null,
    status: "active",
    metadata: {
      lastUsedOn: "2022-07-01T09:18:22.076756Z",
      lastMeasuredBy: "mock-user-2@service-b.test",
      latitude: 60.182027200000002,
      longitude: 24.913417111111111,
      country: "FIN",
      municipality: "Helsinki",
      region: "Etelä-Suomi",
    },
    calibrations: {
      "DS2-0-default": {
        whiteReference: {
          validationPass: true,
          validUntil: "2022-07-31T09:18:20Z",
          secondsLeft: -1,
        },
        spectralReference: {
          validationPass: null,
          validUntil: "2022-09-11T08:49:37Z",
          secondsLeft: 2592000,
        },
      },
    },
    ver: "547550",
  },
  {
    serialNumber: "01A3422000A",
    createdOn: "2022-07-01T09:17:08Z",
    modifiedOn: "2022-07-01T09:18:09Z",
    firmwareVersion: null,
    status: "active",
    metadata: {
      lastUsedOn: "2022-07-01T09:18:09.614854Z",
      lastMeasuredBy: "mock-user-2@service-b.test",
      latitude: 60.182027200000002,
      longitude: 24.913417111111111,
      country: "FIN",
      municipality: "Helsinki",
      region: "Etelä-Suomi",
    },
    calibrations: {
      "DS2-0-default": {
        whiteReference: {
          validationPass: true,
          validUntil: "2022-07-31T09:18:07Z",
          secondsLeft: -1,
        },
        spectralReference: {
          validationPass: null,
          validUntil: "2022-09-11T08:49:37Z",
          secondsLeft: 2592000,
        },
      },
    },
    ver: "537101",
  },
  {
    serialNumber: "01A3422000B",
    createdOn: "2022-07-01T09:17:08Z",
    modifiedOn: "2022-07-01T09:18:11Z",
    firmwareVersion: null,
    status: "active",
    metadata: {
      lastUsedOn: "2022-07-01T09:18:11.365420Z",
      lastMeasuredBy: "mock-user-2@service-b.test",
      latitude: 60.182027200000002,
      longitude: 24.913417111111111,
      country: "FIN",
      municipality: "Helsinki",
      region: "Etelä-Suomi",
    },
    calibrations: {
      "DS2-0-default": {
        whiteReference: {
          validationPass: true,
          validUntil: "2022-07-31T09:18:09Z",
          secondsLeft: -1,
        },
        spectralReference: {
          validationPass: null,
          validUntil: "2022-09-11T08:49:37Z",
          secondsLeft: 2592000,
        },
      },
    },
    ver: "537104",
  },
  {
    serialNumber: "01A3422000C",
    createdOn: "2022-07-01T09:17:08Z",
    modifiedOn: "2022-07-01T09:18:13Z",
    firmwareVersion: null,
    status: "active",
    metadata: {
      lastUsedOn: "2022-07-01T09:18:13.109004Z",
      lastMeasuredBy: "mock-user-2@service-b.test",
      latitude: 60.182027200000002,
      longitude: 24.913417111111111,
      country: "FIN",
      municipality: "Helsinki",
      region: "Etelä-Suomi",
    },
    calibrations: {
      "DS2-0-default": {
        whiteReference: {
          validationPass: true,
          validUntil: "2022-07-31T09:18:11Z",
          secondsLeft: -1,
        },
        spectralReference: {
          validationPass: null,
          validUntil: "2022-09-11T08:49:37Z",
          secondsLeft: 2592000,
        },
      },
    },
    ver: "537108",
  },
  {
    serialNumber: "01A3422000E",
    createdOn: "2022-07-01T09:17:08Z",
    modifiedOn: "2022-07-01T09:18:14Z",
    firmwareVersion: null,
    status: "active",
    metadata: {
      lastUsedOn: "2022-07-01T09:18:14.790587Z",
      lastMeasuredBy: "mock-user-2@service-b.test",
      latitude: 60.182027200000002,
      longitude: 24.913417111111111,
      country: "FIN",
      municipality: "Helsinki",
      region: "Etelä-Suomi",
    },
    calibrations: {
      "DS2-0-default": {
        whiteReference: {
          validationPass: true,
          validUntil: "2022-07-31T09:18:13Z",
          secondsLeft: -1,
        },
        spectralReference: {
          validationPass: null,
          validUntil: "2022-09-11T08:49:37Z",
          secondsLeft: 2592000,
        },
      },
    },
    ver: "537111",
  },
];

export const mockedListAllDevices = async (): Promise<DevicePort[]> => {
  return new Array(8).fill({}).map((_v, index) => ({
    path: `/dev/mockeddevice${index}`,
    ...supportedDeviceMetadata,
    serialNumber: `01A3527000${index}`,
    locationId: "mockedLocationId",
    pnpId: "mockedPnpId",
  }));
};

export const mockCalibrationResponse: WhiteReferenceCalibrationResponse = {
  createdOn: "2022-06-27T07:35:29Z",
  modifiedOn: "2022-07-29T10:17:18Z",
  id: "ABCD1123456",
  daysValid: 21,
  calibrationType: CalibrationType.WHITE_REFERENCE,
  validationPass: true,
};

export const getMockDeviceBySerialNumber = (serialNumber: string): Device => ({
  serialNumber,
  createdOn: "2022-06-21T12:10:56Z",
  modifiedOn: "2022-08-11T08:42:34Z",
  firmwareVersion: null,
  status: "active",
  metadata: {
    lastUsedOn: "2022-08-11T08:42:34.485302Z",
    lastMeasuredBy: "mock-user-1@service-a.test",
    latitude: 52.516280400000001,
    longitude: 13.377701999999999,
    lastCalibrationOnLabscanner: true,
  },
  calibrations: {
    "DS2-0-default": {
      whiteReference: {
        id: "1234",
        validationPass: true,
        validUntil: "2022-09-09T06:50:35Z",
        secondsLeft: 2412057,
      },
      spectralReference: {
        id: "4567",
        validationPass: true,
        validUntil: "2022-09-11T08:49:37Z",
        secondsLeft: 2592000,
      },
    },
  },
  ver: "549502",
});
