import React from "react";
import { Svg, Path, Polygon, Rect, G } from "@react-pdf/renderer";

const PdfSELogo = (): JSX.Element => {
  return (
    <Svg viewBox="0 0 287.61 32.64" width="160">
      <G fill="#3c3c3b" stroke="none">
        <Path d="M33.01,14.71c-.45-.52-13.65-13.31-14.3-13.92-1.13-1.06-2.88-1.11-4.21.2C13.79,1.7,1.49,13.62.88,14.26c-1.07,1.12-1.26,2.61-.01,3.91.57.59,1.94,1.94,3.61,3.57.03,0,.06,0,.1,0,.21-.02.48-.06.77-.14.29-.08.61-.19.91-.36.3-.18.59-.4.83-.7.19-.24.38-.48.57-.74.11-.15,5.43-8.23,6.07-8.93.57-.62,1.73-1.04,2.48-.68.45.22.59.85.55,1.42-.03.38-.64,7.35-.64,7.74,0,.39,0,.77.02,1.14.02.31.14.62.34.8.2.18.78.44,1.51.46.9.02,11.07-.1,11.07-.1,1.92-1.87,3.42-3.35,3.72-3.68.95-1.04.88-2.48.22-3.24" />
        <Path
          fill="#009fe3"
          d="M15.25,24.32s-.87-.03-1.22-.12c-.18-.05-.34-.15-.46-.28-.25-.26-.39-.65-.42-1.03-.02-.25.06-1.71.08-1.99.02-.28.22-2.27.23-2.37,0-.1.02-.2.03-.29.04-.36.07-.66.08-.92.01-.25,0-.46-.04-.61-.04-.16-.12-.27-.26-.34-.13-.07-.32-.12-.56-.08-.23.03-.5.25-.78.54-.03.03-.26.36-.35.51-.06.09-2.07,3.17-2.25,3.44-.18.26-1.5,2.24-1.65,2.42-.18.22-.49.51-1.01.62.07.07,7.76,7.56,8.18,7.96,1.09,1.05,2.43,1.22,3.65.1.5-.47,4.23-4.07,7.76-7.5l-11-.05Z"
        />
        <Path d="M43.47,21.29c.97.9,2.74,2.04,4.86,2.04,1.92,0,2.94-.9,2.94-2.27,0-3.99-8.37-3.36-8.37-9.12,0-3.36,3.02-5.16,6.16-5.16,2.92,0,4.98,1.79,5.36,2.19l-1.72,2.64c-.97-.97-2.34-1.67-3.61-1.67s-2.49.55-2.49,1.84c0,3.31,8.45,2.57,8.45,9.22,0,2.91-2.57,5.51-6.98,5.51-2.72,0-5.41-1.62-6.43-2.69l1.84-2.54Z" />
        <Path d="M57.68,26.1V7.24h4.21c4.74,0,8.18,1.42,8.18,6.08,0,3.76-2.39,6.26-7.53,6.26h-1.32v6.53h-3.54ZM62.47,16.36c2.59,0,3.86-1.17,3.86-3.04,0-1.72-1.1-2.87-3.46-2.87h-1.64v5.91h1.25Z" />
        <Polygon points="72.86 26.08 72.86 7.26 84.07 7.26 84.07 10.45 76.4 10.45 76.4 14.51 82.58 14.51 82.58 17.73 76.4 17.73 76.4 22.86 84.85 22.86 84.85 26.08 72.86 26.08" />
        <Polygon points="108.08 26.1 108.08 10.45 102.53 10.45 102.53 7.26 117.2 7.26 117.2 10.45 111.62 10.45 111.62 26.1 108.08 26.1" />
        <Path d="M149.04,26.1l-1.65-3.46h-8.55l-1.65,3.46h-3.94l9.87-19.69,9.87,19.69h-3.96ZM143.11,13.69l-2.82,5.98h5.68l-2.87-5.98Z" />
        <Polygon points="154.62 26.08 154.62 7.24 158.16 7.24 158.16 22.86 166.04 22.86 166.04 26.08 154.62 26.08" />
        <Polygon points="174.41 26.08 174.41 7.26 185.62 7.26 185.62 10.45 177.95 10.45 177.95 14.51 184.13 14.51 184.13 17.73 177.95 17.73 177.95 22.86 186.4 22.86 186.4 26.08 174.41 26.08" />
        <Polygon points="192.85 14.59 192.9 26.1 189.36 26.1 189.41 6.71 203.27 19.25 203.24 7.24 206.78 7.24 206.73 26.8 192.85 14.59" />
        <Path d="M220.94,18.95v-3.09h8.47c0,8.02-4.64,10.67-9.1,10.67-5.81,0-10.54-4.29-10.54-9.74,0-5.78,4.39-9.97,10.19-9.97,4.61,0,7.15,2.32,7.3,2.47l-1.74,2.67c-.12-.17-2.09-1.94-5.51-1.94-3.96,0-6.48,2.89-6.48,6.73,0,3.49,2.77,6.6,6.8,6.6,2.87,0,5.16-1.94,5.36-4.39h-4.76Z" />
        <Rect x="232.11" y="7.24" width="3.54" height="18.87" />
        <Polygon points="242.75 14.59 242.8 26.1 239.26 26.1 239.31 6.71 253.17 19.25 253.14 7.24 256.68 7.24 256.63 26.8 242.75 14.59" />
        <Polygon points="260.7 26.08 260.7 7.26 271.91 7.26 271.91 10.45 264.24 10.45 264.24 14.51 270.42 14.51 270.42 17.73 264.24 17.73 264.24 22.86 272.68 22.86 272.68 26.08 260.7 26.08" />
        <Path d="M276.05,21.29c.97.9,2.74,2.04,4.86,2.04,1.92,0,2.94-.9,2.94-2.27,0-3.99-8.37-3.36-8.37-9.12,0-3.36,3.02-5.16,6.15-5.16,2.92,0,4.99,1.79,5.36,2.19l-1.72,2.64c-.97-.97-2.34-1.67-3.61-1.67s-2.49.55-2.49,1.84c0,3.31,8.45,2.57,8.45,9.22,0,2.91-2.57,5.51-6.98,5.51-2.72,0-5.41-1.62-6.43-2.69l1.84-2.54Z" />
        <Path d="M127.64,18.75c2.47-.62,4.54-2.14,4.54-5.71,0-5.08-4.66-5.81-8.3-5.81h-4.87v18.87h3.54v-15.65h2.28c2.69,0,3.62,1.05,3.62,2.62,0,1.87-1.44,2.79-4.11,2.79h-.13v3.71l5.8,6.53h4.88l-7.24-7.35Z" />
        <Path d="M100.52,22.57c-.89.41-2.15.77-3.82.77-3.69,0-6.73-2.89-6.73-6.55,0-3.91,2.54-6.78,6.21-6.78,1.74,0,3.16.42,4.34,1.2v-3.6c-1.1-.45-2.49-.79-4.17-.79-6.03,0-10.17,4.51-10.17,9.87s4.46,9.84,10.49,9.84c1.44,0,2.71-.21,3.84-.63v-3.33Z" />
      </G>
    </Svg>
  );
};

export default PdfSELogo;
