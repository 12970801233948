import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  ModelStatuses,
  ProductVariant,
  Model,
  LogService,
  PermissionKeys,
  PermissionRequired,
  ModelApprovalData,
  formatToDateOnly,
  ModelTrainingStatus,
} from "lib-core";
import { Button, FormControlLabel, Switch } from "@mui/material";
import LibraryTable, {
  LibraryTableHeaders,
} from "../components/LibraryTable/LibraryTable";
import { modelsHeaders, ModelWithButtons } from "./helpers";
import useProductVariantModels from "./productVariantModels-hook";
import { TableRowSet } from "../components/LibraryTable/LibraryTableBody";
import LibrarySubheader from "./LibrarySubheader";

const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  width: 100%;
`;

interface ModelsProps {
  productVariant: ProductVariant;
}

const { writeLibraryModellingApproval, readLibraryModellingApproval } =
  PermissionKeys;

const ModelsTab: (props: ModelsProps) => JSX.Element = ({ productVariant }) => {
  // const notification = useNotification();
  const {
    fetchModels,
    loadingModels,
    models,
    approveCandidateModel,
    decommissionModel,
    rejectModel,
  } = useProductVariantModels();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showRejectedAndDecommissioned, setShowRejectedAndDecommissioned] =
    useState<boolean>(false);

  const HeaderColspan = 11;

  useEffect(() => {
    fetchModels(productVariant.id);
  }, [productVariant.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleApprovingCandidate = (modelApprovalData: ModelApprovalData) => {
    approveCandidateModel(modelApprovalData)
      .then(() => {
        fetchModels(productVariant.id);
      })
      .catch((err) => {
        LogService.error(err);
      });
  };

  const handleDecommissioningProduction = (
    modelApprovalData: ModelApprovalData
  ) => {
    decommissionModel(modelApprovalData)
      .then(() => {
        fetchModels(productVariant.id);
      })
      .catch((err) => {
        LogService.error(err);
      });
  };

  const handleRejectingCandidate = (modelApprovalData: ModelApprovalData) => {
    rejectModel(modelApprovalData)
      .then(() => {
        fetchModels(productVariant.id);
      })
      .catch((err) => {
        LogService.error(err);
      });
  };

  const actionButtons = (model: Model) => {
    const modelApprovalData = {
      modelVersionId: model.modelVersionId,
      modelName: model.modelName,
    };

    if (model.modelStatus === "APPROVED_CANDIDATE") {
      return (
        <ActionButtonsContainer>
          <PermissionRequired
            hidden
            permissionKeys={[
              readLibraryModellingApproval,
              writeLibraryModellingApproval,
            ]}
          >
            <Button
              onClick={() => handleApprovingCandidate(modelApprovalData)}
              size="small"
            >
              Release
            </Button>
            <Button
              onClick={() => handleRejectingCandidate(modelApprovalData)}
              variant="outlined"
              size="small"
            >
              Reject
            </Button>
          </PermissionRequired>
        </ActionButtonsContainer>
      );
    }
    if (model.modelStatus === "APPROVED_PRODUCTION") {
      return (
        <ActionButtonsContainer>
          <PermissionRequired
            hidden
            permissionKeys={[
              writeLibraryModellingApproval,
              readLibraryModellingApproval,
            ]}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleDecommissioningProduction(modelApprovalData)}
            >
              Decommission
            </Button>
          </PermissionRequired>
        </ActionButtonsContainer>
      );
    }
    return null;
  };

  const statusMapping: Record<ModelStatuses, string> = {
    [ModelStatuses.APPROVED_PRODUCTION]: "Released model",
    [ModelStatuses.NOT_APPROVED]: "Rejected model",
    [ModelStatuses.APPROVED_CANDIDATE]: "Candidate model",
    [ModelStatuses.DECOMMISSIONED]: "Decommissioned model",
    [ModelStatuses.IN_TRAINING]: "In training",
    [ModelStatuses.WAITING_APPROVAL]: "Waiting approval",
    [ModelStatuses.REJECTED]: "Rejected model",
    [ModelStatuses.ERROR]: "Failed model",
  };

  // Set rowsets
  const statuses: [ModelStatuses[], string][] = [
    [[ModelStatuses.APPROVED_PRODUCTION], "Released model"],
    [[ModelStatuses.APPROVED_CANDIDATE], "Candidate model"],
    [[ModelStatuses.REJECTED], "Rejected model"],
    [[ModelStatuses.DECOMMISSIONED], "Decommissioned model"],
  ];

  // If the "show rejected and decommissioned" switch is toggled on, show everything
  const filteredStatuses = statuses.filter((status) => {
    if (
      !showRejectedAndDecommissioned &&
      (status[0][0] === "REJECTED" || status[0][0] === "DECOMMISSIONED")
    ) {
      return null;
    }
    return status;
  });

  const resolveModelStatus = (model: Model) => {
    // If model training status is in error state
    if (model.modelTrainingStatus === ModelTrainingStatus.ERROR) {
      return "Model training failed";
    }

    // Rest of the models should be returned as is
    return statusMapping[model.modelStatus];
  };

  // Map statuses to rows and set subheader label for each of them
  const rowSets: TableRowSet<ModelWithButtons>[] = filteredStatuses.map(
    ([statuses, title]) => {
      const rows = (models ?? [])
        .filter((model) => {
          const { modelStatus } = model;
          return statuses.includes(modelStatus);
        })
        .map((model) => ({
          ...model,
          modelStatus: resolveModelStatus(model),
          modelSnapshot: model.modelSnapshot
            ? `${formatToDateOnly(model.modelSnapshot.createdOn)}, V${
                model.modelSnapshot.version
              }.0`
            : "",
          actionButtons: actionButtons(model),
        }));

      return {
        label: [
          {
            label: `${title} (${rows.length})`,
            colspan: HeaderColspan,
          },
        ] as LibraryTableHeaders<Record<string, number>>,
        rows,
      };
    }
  );

  const rowCount = useMemo(() => {
    let count = 0;
    models.forEach((model) => {
      if (model.modelStatus === "APPROVED_PRODUCTION") {
        count += 1;
      }
    });
    return count;
  }, [models]);

  const handleChange = () => {
    setShowRejectedAndDecommissioned(!showRejectedAndDecommissioned);
  };

  const modelSwitch = () => {
    return (
      <Switch onChange={handleChange} checked={showRejectedAndDecommissioned} />
    );
  };

  return (
    <>
      <LibrarySubheader
        loading={loadingModels}
        loadingTitle="Loading available models..."
        title={
          rowCount === 1
            ? "1 model available to scanners"
            : `${rowCount} models available to scanners`
        }
        buttons={
          <FormControlLabel
            control={modelSwitch()}
            label="Show rejected and decommissioned models"
          />
        }
      />

      <LibraryTable
        headers={modelsHeaders}
        rowSets={rowSets}
        loading={loadingModels}
      />
    </>
  );
};

export default ModelsTab;
