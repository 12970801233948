import {
  mapMeasureModeToShortUserString,
  PredictionValue,
  StoredMeasurementHistoryItem,
  formatToDateTime,
} from "lib-core";

type RenderProps = {
  measurementId: string;
  product: string;
  batchNumber?: string;
  caseNumber?: string;
  manufactured?: string;
  expires?: string;
  user?: string;
  sensor: string;
  location: string;
  timestamp: string;
  prediction: PredictionValue;
};

interface DetailsViewMeasurementProps {
  content: StoredMeasurementHistoryItem;
  children: (props: RenderProps) => JSX.Element;
}

const MeasurementDetailsProvider: (
  props: DetailsViewMeasurementProps
) => JSX.Element = ({ content, children }) => {
  const prediction = content.metadata.authResult as PredictionValue;

  const {
    dosageForm,
    dosageStrength,
    dosageUnit,
    measureMode,
    globalBrand: productName,
  } = content.metadata;

  const product = `${productName}, ${dosageForm}, ${dosageStrength}${dosageUnit.toLowerCase()}, ${mapMeasureModeToShortUserString(
    measureMode
  )}`;

  const { latitude, longitude, municipality, region, country } =
    content.metadata;

  const humanReadableLocationString = municipality
    ? `${municipality ? `${municipality},` : ""} ${
        region ? `${region},` : ""
      } ${country ? `${country}` : ""}`
    : `N ${latitude.toString().substring(0, 8)}, W ${longitude
        .toString()
        .substring(0, 8)}`;

  const timestamp = formatToDateTime(content.modifiedOn);

  const { manufacturingDate, expiryDate } = content.metadata;

  return children({
    measurementId: content.id,
    product,
    batchNumber: content?.metadata?.batchNumber,
    caseNumber: content?.metadata?.caseNumber,
    manufactured: manufacturingDate,
    expires: expiryDate,
    user: content.measurerPreferredUsername,
    sensor: content.deviceSerialNumber,
    location: humanReadableLocationString,
    timestamp,
    prediction,
  });
};

export default MeasurementDetailsProvider;
