/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  ImportModel,
  PermissionKeys,
  PermissionRequired,
  Model,
  ModelStatuses,
  ModelTrainingStatus,
  ModelApprovalData,
  LogService,
  formatToDateOnly,
  Snapshot,
  usePermissionChecking,
} from "lib-core";
import { Button, Typography } from "@mui/material";
import ModalApprove from "./ModalApprove";
import LibraryTable, {
  LibraryTableHeaders,
} from "../components/LibraryTable/LibraryTable";
import { TableRowSet } from "../components/LibraryTable/LibraryTableBody";

import useProductVariantTraining from "./productVariantTraining-hook";
import useProductVariant from "./productVariant-hook";
import { trainingHeaders, ModelWithButtons } from "./helpers";
import LibrarySubheader from "./LibrarySubheader";

interface TrainingTabProps {
  productVariantId: number;
}

const ActionButtons = styled.nav`
  display: flex;
  justify-content: flex-start;
  gap: 15px;
`;

// Mapping for more user friendly format of status
const statusMapping: Record<ModelStatuses, string> = {
  [ModelStatuses.IN_TRAINING]: "In training",
  [ModelStatuses.WAITING_APPROVAL]: "Waiting approval",
  [ModelStatuses.APPROVED_PRODUCTION]: "Approved, production",
  [ModelStatuses.NOT_APPROVED]: "Not approved",
  [ModelStatuses.APPROVED_CANDIDATE]: "Approved, candidate",
  [ModelStatuses.DECOMMISSIONED]: "Decommissioned",
  [ModelStatuses.REJECTED]: "Rejected",
  [ModelStatuses.ERROR]: "Failed",
};

const { readModelling, writeModelling, createModelling } = PermissionKeys;

const TrainingTab: (props: TrainingTabProps) => JSX.Element = ({
  productVariantId,
}) => {
  const {
    cancelModelTrainingRun,
    fetchModels,
    models,
    loadingModels,
    changeLoading,
    approveModel,
    getProductVariantSnapshots,
    snapshots,
    rejectModel,
    fetchPlatforms,
    platforms,
  } = useProductVariantTraining();

  const { fetchProductVariantById, productVariant } = useProductVariant();
  const requirePermission = usePermissionChecking();
  const modelPlatformPermissions = requirePermission([createModelling]);

  // Fetch product variant information by id and fecth models for list
  const fetchTrainingTabData = () => {
    fetchProductVariantById(productVariantId);
    fetchModels(productVariantId);

    if (modelPlatformPermissions) {
      getProductVariantSnapshots(productVariantId);
      fetchPlatforms();
    }
  };

  const [modal, setModal] = useState(false);
  const [modelName, setName] = useState<string>();
  const [modalState, setModalState] = useState<"approve" | "reject">("approve");
  const [approvalData, setApprovalData] = useState<ModelApprovalData>();

  const onModalClose = () => {
    setModal(false);
    setName("");
  };

  const onModalOpen = (
    modelVersionId: number,
    modelExternalName: string,
    modelName: string,
    state: "approve" | "reject"
  ) => {
    setApprovalData({ modelVersionId, modelName });
    setModal(true);
    setName(`${modelExternalName} (version ${modelVersionId})`);
    setModalState(state);
  };

  // Usable snapshots to start trainings
  const releasedSnapshots =
    productVariant?.metadata.snapshotApprovalStatus.released ?? [];

  const filteredSnapshots = snapshots.filter((obj: Snapshot) => {
    return releasedSnapshots.includes(obj.id);
  });

  const findPreviousCandidate = () => {
    const approved = models.find(
      (model) => model.modelStatus === ModelStatuses.APPROVED_CANDIDATE
    );
    if (approved) {
      return `${approved?.modelExternalName} (version ${approved?.modelVersionId})`;
    }
    return null;
  };

  useEffect(() => {
    fetchTrainingTabData();
  }, [productVariantId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancelingTraining = (viewId: number) => {
    cancelModelTrainingRun(viewId)
      .then(() => {
        fetchTrainingTabData();
      })
      .catch((err) => {
        LogService.error(err);
      });
  };

  const handleApprovingModel = (modelApprovalData: ModelApprovalData) => {
    approveModel(modelApprovalData)
      .then(() => {
        fetchTrainingTabData();
        setModal(false);
        setApprovalData({ modelVersionId: 0, modelName: "" });
      })
      .catch((err) => {
        LogService.error(err);
      });
  };

  const handleRejectingModel = (modelApprovalData: ModelApprovalData) => {
    rejectModel(modelApprovalData)
      .then(() => {
        fetchTrainingTabData();
        setModal(false);
        setApprovalData({ modelVersionId: 0, modelName: "" });
      })
      .catch((err) => {
        LogService.error(err);
      });
  };

  const actionButtons = (
    modelStatus: string,
    viewId: number,
    modelVersionId: number,
    modelExternalName: string,
    modelName: string
  ) => {
    if (modelStatus === ModelStatuses.WAITING_APPROVAL) {
      return (
        <ActionButtons>
          <PermissionRequired
            hidden
            permissionKeys={[readModelling, writeModelling]}
          >
            <Button
              onClick={() =>
                onModalOpen(
                  modelVersionId,
                  modelExternalName,
                  modelName,
                  "approve"
                )
              }
              size="small"
            >
              Approve
            </Button>

            <Button
              onClick={() =>
                onModalOpen(
                  modelVersionId,
                  modelExternalName,
                  modelName,
                  "reject"
                )
              }
              variant="outlined"
              size="small"
            >
              Reject
            </Button>
          </PermissionRequired>
        </ActionButtons>
      );
    }
    if (modelStatus === ModelStatuses.IN_TRAINING) {
      return (
        <ActionButtons>
          <PermissionRequired
            hidden
            permissionKeys={[readModelling, writeModelling]}
          >
            <Button
              onClick={() => fetchTrainingTabData()}
              disabled={changeLoading}
              variant="contained"
              size="small"
            >
              Refresh
            </Button>
            <Button
              onClick={() => handleCancelingTraining(viewId)}
              disabled={changeLoading}
              variant="outlined"
              size="small"
            >
              Cancel
            </Button>
          </PermissionRequired>
        </ActionButtons>
      );
    }
    return null;
  };

  // Set rowsets
  const statuses: [ModelStatuses[], string][] = [
    [[ModelStatuses.IN_TRAINING, ModelStatuses.WAITING_APPROVAL], "New models"],
    [
      [ModelStatuses.APPROVED_PRODUCTION, ModelStatuses.APPROVED_CANDIDATE],
      "Approved",
    ],
    [[ModelStatuses.DECOMMISSIONED], "Decommissioned"],
    [[ModelStatuses.ERROR], "Failed trainings"],
  ];

  // Model status is not quite straigthforward and needs to be resolved.
  const resolveModelStatus = (model: Model) => {
    // If model is in training
    if (model.modelTrainingStatus === ModelTrainingStatus.TRAINING) {
      return ModelStatuses.IN_TRAINING;
    }

    // If model is failed
    if (model.modelTrainingStatus === ModelTrainingStatus.ERROR) {
      return ModelStatuses.ERROR;
    }

    // If model is in approval status
    if (
      model.modelTrainingStatus === ModelTrainingStatus.DONE &&
      ModelStatuses.NOT_APPROVED === model.modelStatus &&
      model.pendingStatus === null
    ) {
      return ModelStatuses.WAITING_APPROVAL;
    }

    // Approved and decommissioned statuses are clear so these can be returned as is
    return model.modelStatus;
  };

  // Map models and resolve status
  const mappedModels = (models ?? []).map((model) => ({
    ...model,
    modelStatus: resolveModelStatus(model),
  }));

  // Map all model metrics into one object
  const headerObject = Object.assign(
    {},
    ...(models ?? [])
      .filter(
        (model) =>
          model.modelStatus !== ModelStatuses.REJECTED &&
          model.modelStatus !== ModelStatuses.DECOMMISSIONED
      )
      .map((model) => ({
        ...model.modelMetrics,
      }))
  );

  // Map the object into key-label pairs (headers)
  const extraHeaders = Object.keys(headerObject).map((key) => {
    return {
      key,
      label: key,
    };
  });

  // Join the original headers and the extra headers
  const joinedHeaders = trainingHeaders.concat(extraHeaders);

  // Map statuses to rows and set subheader label for each of them
  const rowSets: TableRowSet<ModelWithButtons>[] = statuses.map(
    ([statuses, title]) => {
      // Filtering rows with status and map with action buttons
      const rows = mappedModels
        .filter((model) => {
          const { modelStatus } = model;
          return statuses.includes(modelStatus);
        })
        .map((model) => ({
          ...model,
          ...model.modelMetrics,
          modelStatus: statusMapping[model.modelStatus], // Map status to more user friendly
          modelSnapshot: `${formatToDateOnly(
            model.modelSnapshot.createdOn
          )}, V${model.modelSnapshot.version}.0`, // Show snapshot date instead of object
          actionButtons: actionButtons(
            model.modelStatus,
            model.viewId,
            model.modelVersionId,
            model.modelExternalName,
            model.modelName
          ),
        }));

      return {
        label: [
          {
            label: `${title} (${rows.length})`,
            colspan: joinedHeaders.length,
          },
        ] as LibraryTableHeaders<Record<string, number>>,
        rows,
      };
    }
  );

  const rowCount = useMemo(() => {
    let count = 0;
    models.forEach((model) => {
      if (model.modelStatus !== ModelStatuses.REJECTED) {
        count += 1;
      }
    });
    return count;
  }, [models]);

  return (
    <>
      <LibrarySubheader
        loading={loadingModels}
        loadingTitle="Loading model packages..."
        title={
          rowCount === 1
            ? "1 model package added"
            : `${rowCount} model packages added`
        }
        buttons={
          <PermissionRequired hidden permissionKeys={[createModelling]}>
            <ImportModel
              snapshots={filteredSnapshots}
              onSuccess={fetchTrainingTabData}
              platforms={platforms}
            />
          </PermissionRequired>
        }
      />

      <ModalApprove
        state={modalState}
        open={modal}
        onClose={onModalClose}
        onSubmit={() =>
          approvalData && modalState === "approve"
            ? handleApprovingModel(approvalData)
            : approvalData && handleRejectingModel(approvalData)
        }
        newCandidateName={modelName || "New candidate"}
        oldCandidateName={findPreviousCandidate()}
      />

      <LibraryTable
        headers={joinedHeaders}
        rowSets={rowSets}
        loading={loadingModels}
      />
    </>
  );
};

export default TrainingTab;
