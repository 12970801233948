/* eslint-disable react/require-default-props */

import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import {
  PredictionValue,
  StoredMeasurementHistoryItem,
  formatToDateTime,
} from "lib-core";
import MeasurementDetailsProvider from "./MeasurementDetailsProvider";

import PdfSELogo from "../components/svgs/PdfSELogo";

import MontserratMedium from "../components/fonts/Montserrat-Medium.ttf";
import MontserratBold from "../components/fonts/Montserrat-Bold.ttf";

Font.register({
  family: "Montserrat",
  fonts: [
    { src: MontserratMedium },
    { src: MontserratBold, fontWeight: "bold" },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  content: {
    flexDirection: "row",
    paddingVertical: 20,
    paddingRight: 20,
    paddingLeft: 30,
    flexGrow: 1,
  },
  leftPadding: {
    width: 50,
    height: "100%",
  },
  section: {
    flexGrow: 1,
    justifyContent: "space-between",
  },
});

type Props = {
  measurementContent: StoredMeasurementHistoryItem;
  spectraImageBase64: string;
};

const ContentLabel = ({
  children,
  color,
}: {
  children: React.ReactNode;
  color: string;
}) => {
  return (
    <Text
      style={{
        fontSize: 10,
        fontFamily: "Montserrat",
        marginVertical: 5,
        color,
      }}
    >
      {children}
    </Text>
  );
};

const ValueTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <View style={{ marginRight: 10 }}>
      <Text
        style={{
          fontSize: 10,
          fontFamily: "Montserrat",
          fontWeight: "bold",
          marginVertical: 5,
        }}
      >
        {children}
      </Text>
    </View>
  );
};

const KeyValueColumn = ({
  payload,
  keyWidth = "45%",
  valueWidth = "55%",
}: {
  payload: Record<string, React.ReactNode>;
  keyWidth?: string;
  valueWidth?: string;
}) => {
  return (
    <View style={{ flexDirection: "column", width: "100%" }}>
      {Object.entries(payload).map(([key, value]) => {
        return (
          <View style={{ flexDirection: "row", width: "100%" }} key={key}>
            <View style={{ width: keyWidth }}>
              <ValueTitle>{key}</ValueTitle>
            </View>
            <View style={{ width: valueWidth }}>
              {typeof value === "string" ? (
                <ContentLabel color="#333333">{value}</ContentLabel>
              ) : (
                <View>{value}</View>
              )}
            </View>
          </View>
        );
      })}
    </View>
  );
};

const mapPredictionColorForPdf = (predictionValue: PredictionValue): string => {
  switch (predictionValue) {
    case PredictionValue.Pass:
    case PredictionValue.ConfirmedPass:
      return "#5d9710";

    case PredictionValue.Fail:
    case PredictionValue.ConfirmedFail:
      return "#d13a32";
    case PredictionValue.Invalid:
      return "#757575";
    case PredictionValue.Unknown:
      return "#c7a60d";
    default:
      return "#bf7d1a";
  }
};

// Create Document Component
const PdfDocumentContainer = ({
  measurementContent: content,
  spectraImageBase64,
}: Props): JSX.Element => {
  const notes = content.notes ?? [];
  const maybeInspectionNote =
    notes.length < 1 ? "No notes available" : notes[notes.length - 1]?.message;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.content}>
          <View style={styles.section}>
            <View>
              <Text
                style={{
                  fontWeight: "heavy",
                  fontSize: 32,
                  fontFamily: "Montserrat",
                }}
              >
                Measurement report
              </Text>
              <Text style={{ fontSize: 18, fontFamily: "Montserrat" }}>
                NIRONE Web Portal
              </Text>
              <MeasurementDetailsProvider content={content}>
                {({
                  measurementId,
                  product,
                  batchNumber,
                  caseNumber,
                  manufactured,
                  expires,
                  user,
                  sensor,
                  timestamp,
                  location,
                  prediction,
                }) => {
                  const predictionColor = mapPredictionColorForPdf(prediction);
                  return (
                    <View style={{ marginTop: 20 }}>
                      <KeyValueColumn
                        keyWidth="120"
                        valueWidth="380"
                        payload={{
                          "Measurement ID": measurementId,
                          Product: product,
                          User: user,
                        }}
                      />
                      <View style={{ flexDirection: "row" }}>
                        <View style={{ flexGrow: 1, width: "50%" }}>
                          <KeyValueColumn
                            keyWidth="120"
                            valueWidth="120"
                            payload={{
                              "Manufacturing date": manufactured,
                              "Expiration date": expires,
                              "Batch Number": batchNumber,
                              "Case Number": caseNumber ?? "",
                            }}
                          />
                        </View>
                        <View
                          style={{
                            flexGrow: 1,
                            marginLeft: 10,
                            width: "50%",
                          }}
                        >
                          <KeyValueColumn
                            keyWidth="80"
                            valueWidth="160"
                            payload={{
                              Sensor: sensor,
                              Location: location,
                              Timestamp: timestamp,
                              Result: (
                                <View style={{ flexGrow: 1 }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <View
                                      style={{
                                        backgroundColor: predictionColor,
                                        width: 12,
                                        height: 12,
                                        borderRadius: 6,
                                        marginRight: 5,
                                      }}
                                    />
                                    <ContentLabel color={predictionColor}>
                                      {prediction}
                                    </ContentLabel>
                                  </View>
                                </View>
                              ),
                            }}
                          />
                        </View>
                      </View>
                    </View>
                  );
                }}
              </MeasurementDetailsProvider>

              <Image source={spectraImageBase64} />
              <View style={{ flexDirection: "row", marginTop: 20 }}>
                <KeyValueColumn
                  keyWidth="120"
                  valueWidth="380"
                  payload={{
                    "Inspection notes": maybeInspectionNote,
                  }}
                />
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PdfSELogo />
              <Text
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 10,
                  color: "#aaaaaa",
                }}
              >
                Classification: Restricted
              </Text>
              <Text
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 10,
                  color: "#aaaaaa",
                }}
              >
                Generated {formatToDateTime(new Date().toISOString())}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfDocumentContainer;
