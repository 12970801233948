enum PlanningRowStatus {
  DRAFT = "draft",
  IN_APPROVAL = "inApproval",
  RELEASED = "released",
  DECOMMISSIONED = "decommissioned",
}

enum PlanningAction {
  REQUEST = "request",
  APPROVE = "approve",
  REJECT = "reject",
}

interface PlanningRow {
  rowId: number;
  status: PlanningRowStatus;
  pendingStatus: string;
  protocolName: string;
  numberOfMeasurementsPerBatch: number;
  referenceMaterialId: string;
  materialNumber: string;
  globalBrand: string;
  localBrands: string[];
  drugProduct: string;
  variantName: string;
  primaryPackaging: string;
  manufacturingSite: string;
  batchNumber: string;
  name: string;
}

interface PendingChanges {
  protocolName: string | null;
  numberOfMeasurementsPerBatch: number | null;
}

interface PlanningResponse {
  id: number;
  name: string;
  manufacturingSites: PlanningRow[];
  pendingChanges: PendingChanges;
}

enum ResultsRowStatus {
  Inactive = "Inactive",
  Active = "Active",
}

interface ResultsRow {
  firstMeasurementAt: string;
  manufacturingSite: string;
  measurementCount: number;
  metadataUrl: string;
  status: ResultsRowStatus;
}

interface Snapshot {
  id: string;
  createdOn: string;
  modifiedOn: string;
  state: string;
  version: number;
}

interface SnapshotProps {
  version: string;
  snapshotPendingApproval: Snapshot;
  createdOn: string;
  productVariantId: number;
}

interface ResultsResponse {
  id: number;
  name: string;
  latestDraftSnapshot: Snapshot;
  latestReleasedSnapshot: Snapshot;
  incompleteSnapshots: Snapshot[];
  snapshotPendingApproval: Snapshot;
  rows: ResultsRow[];
  testCompletionRate: number;
  testCoverageRate: number;
  ver: string;
}

enum ModelStatuses {
  IN_TRAINING = "IN_TRAINING",
  WAITING_APPROVAL = "WAITING_APPROVAL",
  APPROVED_PRODUCTION = "APPROVED_PRODUCTION",
  NOT_APPROVED = "NOT_APPROVED",
  APPROVED_CANDIDATE = "APPROVED_CANDIDATE",
  DECOMMISSIONED = "DECOMMISSIONED",
  REJECTED = "REJECTED",
  ERROR = "ERROR",
}

enum ModelTrainingStatus {
  TRAINING = "TRAINING",
  DONE = "DONE",
  ERROR = "ERROR",
}

interface Model {
  modelName: string;
  modelExternalName: string;
  modelStatus: ModelStatuses;
  modelSnapshot: Snapshot;
  runId: string;
  pendingStatus: string | null;
  modelTrainingStatus: ModelTrainingStatus;
  viewId: number;
  modelVersionId: number;
  modelMetrics: {
    [key: string]: number;
  };
}

type ModelApprovalData = Pick<Model, "modelVersionId" | "modelName">;

interface ModelsResponse {
  id: number;
  name: string;
  rows: Model[];
  ver: string;
}

export {
  Model,
  ModelsResponse,
  ModelStatuses,
  ModelTrainingStatus,
  PlanningRow,
  PlanningResponse,
  PlanningRowStatus,
  PlanningAction,
  ResultsRow,
  ResultsResponse,
  ResultsRowStatus,
  PendingChanges,
  Snapshot,
  SnapshotProps,
  ModelApprovalData,
};
