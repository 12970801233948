import React, { useState } from "react";
import styled from "styled-components";
import {
  DrugAuthenticationOverride,
  Note,
  PredictionValue,
  StoredMeasurementHistoryItem,
} from "lib-core";
import Button from "@mui/material/Button";

import { ConfirmationParameters, NoteParameters } from "./history-types";
import DetailsViewNotes from "./DetailsViewNotes";
import DetailsViewMeasurement from "./DetailsViewMeasurement";
import DetailsViewSpectra from "./DetailsViewSpectra";
import DownloadPdfButton from "./DownloadPdfButton";
import { useSpectraImage } from "./history-hook";

const Container = styled.div`
  display: grid;
  grid-template:
    "measurement spectra notes" 1fr
    "footer footer footer" min-content / 3fr 4fr 3fr;
`;

const Footer = styled.div`
  grid-area: footer;
  display: flex;
  justify-content: space-between;
  padding: 24px 30px;
  border-top: ${({ theme }) => `1px solid ${theme.palette.grey[200]}`};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

interface HistoryDetailsContentProps {
  closeModal: () => void;
  confirmAuth: (params: ConfirmationParameters) => void;
  content: StoredMeasurementHistoryItem;
  storeNote: (params: NoteParameters) => void;
}

const HistoryDetailsContent: (
  props: HistoryDetailsContentProps
) => JSX.Element = ({ closeModal, confirmAuth, content, storeNote }) => {
  const [
    authConfirmation,
    setAuthConfirmation,
    // eslint-disable-next-line prettier/prettier
  ] = useState<DrugAuthenticationOverride>();

  const spectraData = useSpectraImage(content.id);

  const isAuthDisabled = () => !authConfirmation;

  const saveConfirmation = () => {
    const params = {
      measurementId: content.id,
      result: authConfirmation,
      ver: content.ver,
    };
    confirmAuth(params as ConfirmationParameters);
  };

  const saveNote = (message: string) => {
    const params = {
      measurementId: content.id,
      note: message,
      userId: content.measurerUsername,
    };
    storeNote(params);
  };

  const showSaveButton = () => {
    const prediction = content.metadata.authResult as PredictionValue;
    return prediction === PredictionValue.Unknown;
  };

  return (
    <Container>
      <DetailsViewMeasurement
        content={content}
        setAuthConfirmation={setAuthConfirmation}
      />
      <DetailsViewSpectra content={content} spectraData={spectraData} />
      <DetailsViewNotes notes={content.notes as Note[]} saveNote={saveNote} />
      <Footer>
        <DownloadPdfButton content={content} spectra={spectraData.spectra} />
        <ButtonWrapper>
          <Button onClick={closeModal} variant="outlined">
            Cancel
          </Button>
          {showSaveButton() && (
            <Button
              disabled={isAuthDisabled()}
              onClick={saveConfirmation}
              variant="contained"
            >
              Save Changes
            </Button>
          )}
        </ButtonWrapper>
      </Footer>
    </Container>
  );
};

export default HistoryDetailsContent;
