interface DrugAuthenticationMetadata {
  longitude: number;
  latitude: number;
  batchNumber?: string;
  caseNumber?: string;
  manufacturingDate?: string;
  expiryDate?: string;
  region?: string;
}

interface DrugAuthenticationRequest {
  viewId: number;
  deviceSerialNumber: string;
  value: number[];
  darkValue: number[] | null;
  metadata: DrugAuthenticationMetadata;
}

enum DrugAuthenticationOverride {
  Pass = "ConfirmedPass",
  Fail = "ConfirmedFail",
}

export { DrugAuthenticationOverride, DrugAuthenticationRequest };
