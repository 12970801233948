import {
  CellTypes,
  LocationValue,
  mapMeasureModeToShortUserString,
  PredictionValue,
  StoredMeasurementHistoryItem,
  TableCellType,
  TableDataArray,
} from "lib-core";
import { v4 as uuidv4 } from "uuid";
import format from "date-fns/format";

export const historyTableMapper = (
  history: StoredMeasurementHistoryItem[]
): TableDataArray<StoredMeasurementHistoryItem> => {
  return history.map((e: StoredMeasurementHistoryItem) => {
    const { municipality, region, country, latitude, longitude } = e.metadata;

    return {
      data: e,
      id: e.id,
      row: [
        {
          key: uuidv4(),
          type: CellTypes.STRING,
          value: `${e.metadata.globalBrand}, ${
            e.metadata.dosageStrength
          }${e.metadata.dosageUnit.toLowerCase()}, ${mapMeasureModeToShortUserString(
            e.metadata.measureMode
          )} `,
        } as TableCellType,
        {
          key: uuidv4(),
          type: CellTypes.STRING,
          value: e.metadata.batchNumber,
        } as TableCellType,
        {
          key: uuidv4(),
          type: CellTypes.STRING,
          value: e.metadata.caseNumber,
        } as TableCellType,
        {
          key: uuidv4(),
          type: CellTypes.RESULT,
          value: e.metadata?.authResult || PredictionValue.Unknown,
        } as TableCellType,
        {
          key: uuidv4(),
          type: CellTypes.STRING,
          value: e.measurerPreferredUsername,
        } as TableCellType,
        {
          key: uuidv4(),
          type: CellTypes.STRING,
          value: e.deviceSerialNumber,
        } as TableCellType,
        {
          key: uuidv4(),
          type: CellTypes.LOCATION,
          value: {
            municipality,
            region,
            country,
            north: latitude.toString(),
            west: longitude.toString(),
          } as unknown as LocationValue,
        } as TableCellType,
        {
          key: uuidv4(),
          type: CellTypes.STRING,
          value: format(new Date(e.createdOn), "yyyy-MM-dd HH:mm"),
        } as TableCellType,
      ],
    };
  });
};
