import { v4 as uuidv4 } from "uuid";
import axios from "../../AxiosInstance";
import {
  DrugAuthenticationRequest,
  DrugAuthenticationOverride,
} from "../../types/contracts/DrugAuthenticationRequest";
import StoredMeasurementHistoryItem from "../../types/contracts/StoredMeasurementHistoryItem";
import Urls from "../Urls";

const performAuthentication = async (
  requestData: DrugAuthenticationRequest
): Promise<StoredMeasurementHistoryItem> => {
  const measurementId = uuidv4();
  const response = await axios({
    method: "post",
    url: Urls.drugAuthentication(measurementId),
    data: requestData,
  });
  return response.data;
};

const updateAuthentication = async (
  authenticationOverride: DrugAuthenticationOverride,
  measurementId: string,
  ver: string
): Promise<StoredMeasurementHistoryItem> => {
  const request = {
    metadata: {
      authResult: authenticationOverride,
    },
    ver,
  };

  const response = await axios({
    method: "patch",
    url: Urls.drugAuthentication(measurementId),
    data: request,
  });
  return response.data;
};

const DrugAuthenticationService = {
  performAuthentication,
  updateAuthentication,
};

export default DrugAuthenticationService;
