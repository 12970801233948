/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable import/no-named-as-default */
import React from "react";
import styled from "styled-components";
import { default as MuiTableBody } from "@mui/material/TableBody";
import { default as MuiTableRow } from "@mui/material/TableRow";

import TableCellCoords from "./TableCellCoords";
import TableCellLocation from "./TableCellLocation";
import TableCellDateTime from "./TableCellDateTime";
import TableCellExpiredCheck from "./TableCellExpiredCheck";
import TableCellLastUse from "./TableCellLastUse";
import TableCellResult from "./TableCellResult";
import TableCellString from "./TableCellString";
import TableCellSelect from "./TableCellSelect";

import {
  CellTypes,
  TableCallbackFn,
  TableDataArray,
  TableSnapShot,
} from "./datatable-types";

type ListTableRowsProps<T> = {
  onClick?: TableCallbackFn;
  rows: TableDataArray<T>;
  snapshot: TableSnapShot;
  loading?: boolean;
};

type TableRowProps = {
  $isClickable: boolean;
};

const selectCursor = ($isClickable: boolean) =>
  $isClickable ? "pointer" : "default";

const TableRow = styled(MuiTableRow)<TableRowProps>`
  &:hover td,
  &:hover th {
    cursor: ${({ $isClickable }) => `${selectCursor($isClickable)} !important`};
    background-color: ${({ theme }) => theme.table.hover};
  }
`;

const TableBody = styled(MuiTableBody)`
  transition: opacity 0.15s;

  &.loading {
    opacity: 0.35;
  }
`;

const ListTableRows: <T>(props: ListTableRowsProps<T>) => JSX.Element = ({
  onClick,
  rows,
  snapshot,
  loading = false,
}) => {
  const rowClickEvent = (
    ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    data: unknown
  ) => {
    const target = ev.target as Element;
    const callback = onClick as TableCallbackFn;
    if (target.nodeName !== "A" && callback) callback(data, snapshot);
  };

  return (
    <TableBody className={loading ? "loading" : ""}>
      {rows.map((row) => {
        return (
          <TableRow
            $isClickable={!!onClick}
            key={row.id}
            onClick={(ev) => rowClickEvent(ev, row.data)}
          >
            {row.row.map((cell) => {
              switch (cell.type) {
                case CellTypes.COORDS:
                  return <TableCellCoords key={cell.key} value={cell.value} />;
                case CellTypes.LOCATION:
                  return (
                    <TableCellLocation key={cell.key} value={cell.value} />
                  );

                case CellTypes.DATETIME:
                  return (
                    <TableCellDateTime
                      key={cell.key}
                      value={cell.value}
                      format={cell.format}
                    />
                  );

                case CellTypes.EXPIRED:
                  return (
                    <TableCellExpiredCheck key={cell.key} value={cell.value} />
                  );
                case CellTypes.LASTUSE:
                  return <TableCellLastUse key={cell.key} value={cell.value} />;
                case CellTypes.RESULT:
                  return <TableCellResult key={cell.key} value={cell.value} />;

                case CellTypes.STRING:
                  return (
                    <TableCellString
                      key={cell.key}
                      value={cell.value}
                      extra={cell.extra}
                    />
                  );

                case CellTypes.SELECT:
                  return (
                    <TableCellSelect
                      key={cell.key}
                      options={cell.options}
                      value={cell.value}
                      onChange={cell.onChange}
                      extra={cell.extra}
                    />
                  );
                default:
                  return <React.Fragment key="unknown">{cell}</React.Fragment>;
              }
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default ListTableRows;
