import { AxiosResponse } from "axios";

import axios from "../../AxiosInstance";
import { WhiteReferenceCalibrationResponse } from "../../models/contracts/device/dto/calibration";
import { SaveCalibrationResultRequest } from "../../models/contracts/device/dto/measurement";
import { MetaData, Device } from "../../types/contracts/Device";
import ExpireData from "../../types/contracts/ExpireData";
import Protocol from "../../types/contracts/Protocol";
import {
  HttpConfig,
  PagedResult,
  PagingData,
  Sorting,
} from "../../types/types-api";
import { MeasurementResult } from "../../types/types-measurement";
import LogService from "../LogService";
import Urls from "../Urls";

const getDevice = async (serialNumber: string): Promise<Device> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.deviceBySerialNumber(serialNumber),
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};

const getExtDevice = async (
  serialNumber: string,
  metadata?: Record<string, string>
): Promise<Device> => {
  const params = new URLSearchParams();
  if (metadata) {
    Object.entries(metadata).map(([param, value]) =>
      params.append(param, value)
    );
  }
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    params,
    url: Urls.deviceBySerialNumber(serialNumber),
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};

// Device paged listing
const getPagedDevices = async (
  paging: PagingData,
  metadata?: Record<string, string>,
  sorting?: Sorting[]
): Promise<PagedResult<Device>> => {
  const params = new URLSearchParams();
  Object.entries(paging).map(([param, value]) => params.append(param, value));
  if (metadata) {
    Object.entries(metadata).map(([param, value]) =>
      params.append(param, value)
    );
  }
  if (sorting) {
    sorting.forEach((value) => {
      const { direction, key } = value;
      params.append("sortBy", `${direction}:${key}`);
    });
  }
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    params,
    url: Urls.allDevices(),
  };

  const httpResult = await axios(httpConfig);
  const { page, perPage, total, entities } = httpResult.data;
  return { paging: { page, perPage, total }, entities };
};

const searchDevicesBySerialNumbers = async (
  serialNumbers: string[]
): Promise<Device[]> => {
  const serialNumbersToQuery = serialNumbers.reduce((result, serialNumber) => {
    if (result === "") {
      return `deviceSerialNumber=${serialNumber}`;
    }
    return `${result}&deviceSerialNumber=${serialNumber}`;
  }, "");

  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: `${Urls.allDevices()}?${serialNumbersToQuery}`,
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data?.entities;
};

const getDevices = async (): Promise<Device[]> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: `${Urls.allDevices()}`,
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data?.entities;
};

const getProtocols = async (): Promise<Protocol[]> => {
  const httpConfig: HttpConfig = {
    method: "get",
    url: Urls.deviceExtProtocols(),
  };

  const httpResult: AxiosResponse<Protocol[]> = await axios(httpConfig);
  return httpResult.data;
};

const getProtocol = async (protocolName: string): Promise<Protocol> => {
  const httpConfig: HttpConfig = {
    method: "get",
    url: Urls.deviceExtProtocol(protocolName),
  };

  const httpResult: AxiosResponse<Protocol> = await axios(httpConfig);
  return httpResult.data;
};

const getExpireData = async (
  serialNumber: string,
  protocolName: string
): Promise<ExpireData | undefined> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.getExpireData(serialNumber, protocolName),
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};

const getPQExpireData = async (
  serialNumber: string,
  protocolName: string
): Promise<ExpireData | undefined> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.getPQExpireData(serialNumber, protocolName),
  };

  const httpResult = await axios(httpConfig);
  return httpResult.data;
};

const getWhiteReference = async (
  serialNumber: string,
  protocolName: string
): Promise<unknown> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.getWhiteReference(serialNumber, protocolName),
  };

  const httpResult = await axios(httpConfig);
  return httpResult;
};

const saveWhiteReference = async (
  serialNumber: string,
  protocolName: string,
  calibrationData: MeasurementResult
): Promise<void> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "put",
    url: Urls.saveWhiteReference(serialNumber, protocolName),
    data: calibrationData,
  };

  const httpResult = await axios(httpConfig);
  LogService.log("Could save white reference data", httpResult);
};

const saveSpectralRefence = async (
  serialNumber: string,
  protocolName: string,
  calibrationData: MeasurementResult
): Promise<void> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "put",
    url: Urls.saveSpectralReference(serialNumber, protocolName),
    data: calibrationData,
  };

  const httpResult = await axios(httpConfig);
  LogService.log("Could save white reference data", httpResult);
};

const updateDevice = async (
  serialNumber: string,
  data: {
    ver?: string;
    status?: string;
    message?: string;
    firmwareVersion?: string;
    metadata?: MetaData;
  }
): Promise<void> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "patch",
    url: Urls.updateDevice(serialNumber, data.status as string),
    data: { message: data.message },
  };

  const httpResult = await axios(httpConfig);
  LogService.log("Could update device", httpResult);
};

const updateDeviceStatus = async (
  serialNumber: string,
  data: {
    ver?: string;
    status?: string;
    message?: string;
    firmwareVersion?: string;
    metadata?: MetaData;
  }
): Promise<void> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "patch",
    url: Urls.updateDeviceStatus(serialNumber, data.status as string),
    data: { message: data.message },
  };

  const httpResult = await axios(httpConfig);
  LogService.log("Could update device", httpResult);
};

const importCSVDeviceList = async (formData: FormData): Promise<unknown> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "post",
    url: Urls.importFromCsv(),
    data: formData,
  };

  const httpResult = await axios(httpConfig);
  return httpResult;
};

const saveOQCalibrationResult = async ({
  protocolName,
  serialNumber,
  value,
  darkValue,
  isLabscanner = false,
}: SaveCalibrationResultRequest): Promise<WhiteReferenceCalibrationResponse> => {
  const httpConfig: HttpConfig = {
    method: "put",
    url: Urls.saveOQCalibration(serialNumber, protocolName, isLabscanner),
    data: { value, darkValue },
  };

  const httpResult: AxiosResponse<WhiteReferenceCalibrationResponse> =
    await axios(httpConfig);

  return httpResult.data;
};
saveOQCalibrationResult.query = "device/save-OQ-calibration-result";

const savePQCalibrationResult = async ({
  protocolName,
  serialNumber,
  value,
  darkValue,
}: SaveCalibrationResultRequest): Promise<WhiteReferenceCalibrationResponse> => {
  const httpConfig: HttpConfig = {
    method: "put",
    url: Urls.saveSpectralReference(serialNumber, protocolName),
    data: { value, darkValue },
  };

  const httpResult: AxiosResponse<WhiteReferenceCalibrationResponse> =
    await axios(httpConfig);

  return httpResult.data;
};
savePQCalibrationResult.query = "device/save-PQ-calibration-result";

const listRegisteredDevices = async (): Promise<string[]> => {
  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: `${Urls.devicesExt()}?page=0&perPage=100`,
  };

  const httpResult: AxiosResponse<{ entities: Device[] }> = await axios(
    httpConfig
  );
  return httpResult.data.entities.map((device) => device.serialNumber);
};
listRegisteredDevices.query = "registered-devices";

const fetchDevicesMetadata = async (
  serialNumbers: string[]
): Promise<Device[]> => {
  const serialNumberQuery = serialNumbers.reduce((acc, serial) => {
    return `${acc}&deviceSerialNumber=${serial}`;
  }, "");

  const httpConfig: HttpConfig<unknown> = {
    method: "get",
    url: Urls.devicesExtDetailBySerialNumbers(serialNumberQuery),
  };

  const httpResult: AxiosResponse<{ entities: Device[] }> = await axios(
    httpConfig
  );
  return httpResult.data.entities;
};
fetchDevicesMetadata.query = "device-metadata/fetch";

const DeviceService = {
  getDevice,
  getExtDevice,
  getPagedDevices,
  searchDevicesBySerialNumbers,
  getDevices,
  getExpireData,
  getPQExpireData,
  getProtocol,
  getProtocols,
  getWhiteReference,
  saveWhiteReference,
  saveSpectralRefence,
  updateDevice,
  updateDeviceStatus,
  importCSVDeviceList,
  fetchDevicesMetadata,
  listRegisteredDevices,
  saveOQCalibrationResult,
  savePQCalibrationResult,
};

export default DeviceService;
