import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  createFilter,
  DataFilter,
  DataTable,
  FilterTypes,
  KeyValueParams,
  ImportCSV,
  DeviceService,
  PermissionKeys,
  usePermissionChecking,
  Sorting,
  PermissionRequired,
} from "lib-core";

import { useHistory } from "react-router-dom";
import { Button as MuiButton } from "@mui/material";

import Page from "../components/Layout/Page";
import { PageContent, PageSidebar } from "../components/Layout/PageContent";
import useDevices from "./device-hook";
import { headers, mapDevices } from "./device-helpers";
import Header from "../components/Layout/Header";
import { downloadCsv } from "../shared/csv-helpers";

const Button = styled(MuiButton)`
  margin-right: 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; // optional, use this if you want to space out your buttons
`;

const filters = [
  createFilter("deviceSerialNumber", "Serial number", FilterTypes.String),
  {
    name: "status",
    placeholder: "Status",
    type: FilterTypes.Autocomplete,
    value: "",
    options: ["active", "inactive", "decommissioned"],
  },
  createFilter("firmwareVersion", "Firmware version", FilterTypes.String),
  createFilter("oqCalibration", "OQ Calibration", FilterTypes.Date),
  createFilter("pqCalibration", "PQ Calibration", FilterTypes.Date),
  createFilter("lastMeasuredBy", "Last measured by", FilterTypes.String),
  createFilter("lastUsedOn", "Last measurement time", FilterTypes.Date),
];

const rowsPerPageOptions = [10, 25, 50, 100];

const DeviceHomeView: () => JSX.Element = () => {
  const { importDevice, readSensorManagement, readWebPortal } = PermissionKeys;
  const history = useHistory();
  const [filterState, setFilterState] = useState<KeyValueParams>([]);
  const [perPage, setPerPage] = useState(10);
  const [isExportingCSV, setIsExportingCSV] = useState(false);
  const requirePermission = usePermissionChecking();
  const allowSensorManagement = requirePermission([
    readSensorManagement,
    readWebPortal,
  ]);

  useEffect(() => {
    if (allowSensorManagement === false) {
      history.replace("/error-403");
    }
  }, [allowSensorManagement, history]);

  const {
    devices,
    loading,
    fetchAndSetStateDevices,
    fetchDevices,
    pagination,
  } = useDevices();

  const fetchDeviceList = (
    page: number,
    perPage: number,
    filterState?: KeyValueParams,
    sorting?: Sorting[]
  ) => {
    fetchAndSetStateDevices(page, perPage, filterState, sorting);
  };

  const onFilterUpdate = (params: KeyValueParams) => {
    setFilterState(params);
    fetchDeviceList(0, perPage, params);
  };

  const exportCSV = () => {
    setIsExportingCSV(true);
    const csvHeader = headers.map((h) => h.label).join(",");
    // API has limits of max page size, so if total amount is over the limit
    // request must be splitted chunks and handled after all chunks have been loaded
    const apiPageLimit = 200;
    const chunks = pagination?.total
      ? Math.ceil(pagination?.total / apiPageLimit)
      : 0;
    const pageRequests = Array.from(Array(chunks), (_, i) => i);
    const requestPromises = pageRequests.map((page) =>
      fetchDevices(page, apiPageLimit, filterState).then(
        (result) => result.entities
      )
    );
    // Wait all promises to be fulfilled
    Promise.all(requestPromises)
      .then((devices) => {
        const entities = devices.reduce((acc, page) => [...acc, ...page], []);
        // Map device rows to headers and join row with CSV delimiter
        // Use value or in more complex fields, stringValue field for simple string value
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const mappedDeviceRows = mapDevices(entities).map((device) =>
          device.row.map((field) => field.stringValue ?? field.value).join(",")
        );

        // Join headers and rows together with newlines and output file
        const url = `data:text/csv;charset=utf-8,${encodeURI(
          [csvHeader, ...mappedDeviceRows].join("\n")
        )}`;
        const fileName = "sensors.csv";
        downloadCsv(fileName, url);
      })
      .finally(() => {
        setIsExportingCSV(false);
      });
  };

  return (
    <>
      <Header title="Sensor Management">
        <ButtonContainer>
          <Button
            data-testid="export-csv-button"
            disabled={devices.length === 0 || isExportingCSV}
            onClick={exportCSV}
            variant="outlined"
          >
            {isExportingCSV ? "Exporting CSV ..." : "Export CSV"}
          </Button>
          <PermissionRequired permissionKeys={[importDevice]}>
            <ImportCSV
              service={DeviceService.importCSVDeviceList}
              onSuccess={() => fetchDeviceList(0, perPage)}
              closeAfterSuccess
            />
          </PermissionRequired>
        </ButtonContainer>
      </Header>
      <Page>
        <PageSidebar>
          <DataFilter delay={500} filters={filters} onChange={onFilterUpdate} />
        </PageSidebar>
        <PageContent>
          <DataTable
            loading={loading}
            id="device-table"
            headers={headers}
            rows={devices}
            onPageChange={(page: number, perPage: number) => {
              fetchDeviceList(page, perPage);
            }}
            pagination={pagination}
            rowsPerPageOptions={rowsPerPageOptions}
            onPerPageChange={setPerPage}
            onSorting={(sorting) => {
              fetchDeviceList(0, perPage, filterState, sorting);
            }}
            singleColumnSort
          />
        </PageContent>
      </Page>
    </>
  );
};

export default DeviceHomeView;
