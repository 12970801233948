import { AxiosError } from "axios";
import {
  ProductService,
  Model,
  ModelApprovalData,
  useError,
  useHttpErrorReader,
} from "lib-core";
import { useState } from "react";

type ModelsData = {
  fetchModels(variantId: number): void;
  models: Model[];
  loadingModels: boolean;
  approveCandidateModel(modelApprovalData: ModelApprovalData): Promise<void>;
  decommissionModel(modelApprovalData: ModelApprovalData): Promise<void>;
  rejectModel(modelApprovalData: ModelApprovalData): Promise<void>;
  changeLoading: boolean;
  error: unknown | undefined;
};

const useProductVariantModels = (): ModelsData => {
  const [models, setModels] = useState<Model[]>([]);
  const [loadingModels, setLoadingModels] = useState<boolean>(false);
  const [changeLoading, setChangeLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const { handleErrors } = useError();
  const errorReader = useHttpErrorReader();

  const fetchModels = async (variantId: number) => {
    setLoadingModels(true);
    setError(undefined);
    try {
      const result = await ProductService.getProductVariantModels(variantId);
      setModels(result.rows);
      setLoadingModels(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setLoadingModels(false);
      setError(err);
    }
    setLoadingModels(false);
    setError(undefined);
  };

  const approveCandidateModel = async (
    modelApprovalData: ModelApprovalData
  ) => {
    setChangeLoading(true);
    setError(undefined);
    try {
      await ProductService.approveCandidateModel(modelApprovalData);
      setChangeLoading(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setChangeLoading(false);
      setError(err);
    }
    setChangeLoading(false);
    setError(undefined);
  };

  const rejectModel = async (modelApprovalData: ModelApprovalData) => {
    setChangeLoading(true);
    setError(undefined);
    try {
      await ProductService.rejectModel(modelApprovalData);
      setChangeLoading(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setChangeLoading(false);
      setError(err);
    }
    setChangeLoading(false);
    setError(undefined);
  };

  const decommissionModel = async (modelApprovalData: ModelApprovalData) => {
    setChangeLoading(true);
    setError(undefined);
    try {
      await ProductService.decommissionModel(modelApprovalData);
      setChangeLoading(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setChangeLoading(false);
      setError(err);
    }
    setChangeLoading(false);
    setError(undefined);
  };

  return {
    fetchModels,
    models,
    loadingModels,
    approveCandidateModel,
    decommissionModel,
    rejectModel,
    changeLoading,
    error,
  };
};

export default useProductVariantModels;
