import React from "react";
import styled from "styled-components";
import {
  DrugAuthenticationOverride,
  PermissionKeys,
  PermissionRequired,
  PredictionValue,
  StoredMeasurementHistoryItem,
} from "lib-core";
import Table from "@mui/material/Table";
import Typography from "@mui/material/Typography";

import DetailsViewAuthentication from "./DetailsViewAuthentication";
import MeasurementDetailsProvider from "./MeasurementDetailsProvider";
import { Td, Th } from "../components/keyValueTable/keyValueTable";

interface DetailsViewMeasurementProps {
  content: StoredMeasurementHistoryItem;
  setAuthConfirmation: (result: DrugAuthenticationOverride) => void;
}

const Container = styled.div`
  grid-area: measurement;
  padding: 24px 30px;
`;

const Headline = styled(Typography)`
  margin-bottom: 12px;
`;

const Result = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;

  &.result-pass {
    color: ${({ theme }) => theme.palette.success.main};
  }

  &.result-fail {
    color: ${({ theme }) => theme.palette.error.main};
  }

  &.result-unknown {
    color: ${({ theme }) => theme.palette.warning.main};
  }
  &.result-invalid {
    color: ${({ theme }) => theme.palette.brandGray.dark};
  }
`;

const ResultIndicator = styled.span`
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 1.1rem;
  background: currentColor;
`;

const { measurementStatusChange } = PermissionKeys;

const DetailsViewMeasurement: (
  props: DetailsViewMeasurementProps
) => JSX.Element = ({ content, setAuthConfirmation }) => {
  const updateConfirmation = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setAuthConfirmation(ev.target.value as DrugAuthenticationOverride);
  };

  return (
    <Container>
      <Headline variant="h3">Measurement details</Headline>
      <Table aria-label="measurement details table">
        <MeasurementDetailsProvider content={content}>
          {({
            measurementId,
            product,
            batchNumber,
            caseNumber,
            manufactured,
            expires,
            user,
            sensor,
            timestamp,
            location,
            prediction,
          }) => {
            const mapPredictionToClassName = () => {
              switch (prediction) {
                case PredictionValue.Fail:
                case PredictionValue.ConfirmedFail:
                  return "result-fail";
                case PredictionValue.Unknown:
                  return "result-unknown";
                case PredictionValue.Invalid:
                  return "result-invalid";
                default:
                  return "result-pass";
              }
            };
            const predictionClassName = mapPredictionToClassName();

            return (
              <tbody>
                <tr>
                  <Th>Measurement ID</Th>
                  <Td>{measurementId}</Td>
                </tr>
                <tr>
                  <Th>Product</Th>
                  <Td>{product}</Td>
                </tr>
                <tr>
                  <Th>Batch number</Th>
                  <Td>{batchNumber}</Td>
                </tr>
                <tr>
                  <Th>Case number</Th>
                  <Td>{caseNumber}</Td>
                </tr>
                <tr>
                  <Th>Manufacturing date</Th>
                  <Td>{manufactured}</Td>
                </tr>
                <tr>
                  <Th>Expiration date</Th>
                  <Td>{expires}</Td>
                </tr>
                <tr>
                  <Th>User</Th>
                  <Td>{user}</Td>
                </tr>
                <tr>
                  <Th>Sensor</Th>
                  <Td>{sensor}</Td>
                </tr>
                <tr>
                  <Th>Location</Th>
                  <Td>{location}</Td>
                </tr>
                <tr>
                  <Th>Timestamp</Th>
                  <Td>{timestamp}</Td>
                </tr>
                <tr>
                  <Th>Result</Th>
                  <Td>
                    <Result className={predictionClassName}>
                      <ResultIndicator />
                      {prediction}
                    </Result>
                  </Td>
                </tr>
                <PermissionRequired
                  hidden
                  permissionKeys={[measurementStatusChange]}
                >
                  <DetailsViewAuthentication
                    result={prediction}
                    updateConfirmation={updateConfirmation}
                  />
                </PermissionRequired>
              </tbody>
            );
          }}
        </MeasurementDetailsProvider>
      </Table>
    </Container>
  );
};

export default DetailsViewMeasurement;
