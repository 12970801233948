import React from "react";
import { DrugAuthenticationOverride, PredictionValue } from "lib-core";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import styled from "styled-components";
import theme from "../theme";
import { Td, Th } from "../components/keyValueTable/keyValueTable";

interface DetailsViewAuthenticationProps {
  result: PredictionValue;
  updateConfirmation: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

const ThPadded = styled(Th)`
  padding-top: 16px;
`;

const DetailsViewAuthentication: (
  props: DetailsViewAuthenticationProps
) => JSX.Element = ({ result, updateConfirmation }) => {
  if (result !== PredictionValue.Unknown) {
    return <></>;
  }

  return (
    <tr>
      <ThPadded>Authentication</ThPadded>
      <Td>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="authentication options"
            name="radio-buttons-group"
            onChange={updateConfirmation}
          >
            <FormControlLabel
              value={DrugAuthenticationOverride.Pass}
              control={<Radio value="ConfirmedPass" />}
              label="Authentication Pass"
              componentsProps={{
                typography: {
                  color: theme.palette.primary.main,
                },
              }}
            />
            <FormControlLabel
              value={DrugAuthenticationOverride.Fail}
              control={<Radio value="ConfirmedFail" />}
              label="Authentication Fail"
              componentsProps={{
                typography: {
                  color: theme.palette.primary.main,
                },
              }}
            />
          </RadioGroup>
        </FormControl>
      </Td>
    </tr>
  );
};

export default DetailsViewAuthentication;
