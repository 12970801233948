/* eslint-disable import/no-cycle */
import React, { useLayoutEffect } from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import {
  CurrentUserService,
  PermissionKeys,
  usePermissionChecking,
} from "lib-core";
import { useHistory } from "react-router-dom";
import Header from "../components/Layout/Header";
import Navigation from "../components/navigation/Navigation";
import appViews from "../router/views";
import Audit from "../audit/Audit";

const Container = styled.div`
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1200px;
  margin: 5rem auto 9rem;
`;

const Greeting = styled.div`
  margin-bottom: 48px;

  p {
    margin: 4px 0 0;
    font-size: 24px;
  }
`;

const HomeView: () => JSX.Element = () => {
  const name = CurrentUserService.getCurrentUser()?.preferredUsername;
  const history = useHistory();
  const { readWebPortal } = PermissionKeys;
  const requirePermission = usePermissionChecking();
  const allowWebPortal = requirePermission([readWebPortal]);

  useLayoutEffect(() => {
    if (name && allowWebPortal === false) {
      history.replace("/error-403");
    }
  }, [allowWebPortal, history, name]);

  return (
    <>
      <Header title="Home">
        <Audit />
      </Header>
      <Container>
        <Greeting>
          {name ? <Typography variant="h2">Hi, {name}!</Typography> : null}
          <p>Welcome to NIRONE Web Portal.</p>
        </Greeting>
        <Navigation appViews={appViews} />
      </Container>
    </>
  );
};

export default HomeView;
