import React from "react";
import styled from "styled-components";
import { TableCell as MuiTableCell } from "@mui/material";
import PredictionValue from "../../types/contracts/PredictionValue";

interface TableCellResultProps {
  value: string;
}

interface TableCellProps {
  state: string;
}

interface AppTheme {
  palette: {
    error: { main: string };
    success: { main: string };
    warning: { main: string };
  };
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const selectStateColor = (state: string, theme: AppTheme) => {
  switch (state) {
    case PredictionValue.Fail:
    case PredictionValue.ConfirmedFail:
      return theme.palette.error.main;
    case PredictionValue.Invalid:
      return "#9d9d9d";
    case PredictionValue.Unknown:
      return theme.palette.warning.main;
    default:
      return theme.palette.success.main;
  }
};

const TableCell = styled(MuiTableCell)<TableCellProps>`
  color: ${({ state, theme }) =>
    `${selectStateColor(state, theme)} !important`};
`;

export const TableCellResult: (props: TableCellResultProps) => JSX.Element = ({
  value,
}) => (
  <TableCell state={value}>
    <Container>{value}</Container>
  </TableCell>
);

export default TableCellResult;
