import React, { useEffect, useState } from "react";
import {
  formatToDateOnly,
  LogService,
  NotificationTypes,
  PermissionKeys,
  PermissionRequired,
  ResultsResponse,
  Snapshot,
  useNotification,
} from "lib-core";
import { Typography, Button as MuiButton } from "@mui/material";
import styled from "styled-components";
import useProductVariantResults from "../library/productVariantResults-hook";

const Container = styled.main`
  display: flex;
  flex-direction: column;
  width: 430px;
  background: ${({ theme }) => theme.palette.brandGray.light};
  padding: 20px;
`;
const TitleBox = styled.div`
  width: 100%;
`;
const TextContainer = styled.div``;

const ButtonBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const Button = styled(MuiButton)`
  min-width: 110px;
  height: 40px;
  align-items: center;
  padding: 0 0;
  margin: 5px;
`;

const WideButton = styled(MuiButton)`
  min-width: 200px;
  height: 40px;
  align-items: center;
  padding: 0 0;
  margin: 5px;
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface SnapshotProps {
  productVariantId: number;
  results: ResultsResponse;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const {
  writeLibraryPlanningApproval,
  readProductLibrary,
  writeProductLibrary,
  createAuthentifieldSnapshot,
} = PermissionKeys;

const SnapshotComponent: (props: SnapshotProps) => JSX.Element = ({
  productVariantId,
  results,
}) => {
  const [snapshotButtonDisabled, setSnapshotButtonDisabled] =
    useState<boolean>(false);
  const {
    createProductVariantSnapshot,
    updateProductVariantSnapshot,
    fetchResults,
  } = useProductVariantResults();
  const [uuid, setUuid] = useState<string>("");
  const [snapshotStatus, setSnapshotStatus] = useState<Record<string, string>>(
    {}
  );
  const notification = useNotification();

  const getActiveMeasurementCount = () => {
    let count = 0;
    results.rows?.forEach((row) => {
      if (row.status === "Active") {
        count += row.measurementCount;
      }
    });
    return count;
  };

  const getSnapshotStatus = (results: ResultsResponse) => {
    if (results.incompleteSnapshots && results.incompleteSnapshots[0]) {
      setSnapshotStatus({
        title: "Snapshot creation in progress",
        state: "incomplete",
        createdOn: `Requested on ${formatToDateOnly(
          results.incompleteSnapshots[0].createdOn
        )}`,
        version: `V${results.incompleteSnapshots[0].version}.0`,
      });
      return;
    }
    if (results.snapshotPendingApproval) {
      setSnapshotStatus({
        title: "Snapshot approval pending",
        state: "pending",
        createdOn: `Requested on ${formatToDateOnly(
          results.snapshotPendingApproval.createdOn
        )}`,
        version: `V${results.snapshotPendingApproval.version}.0`,
      });
      return;
    }
    if (results.latestReleasedSnapshot) {
      setSnapshotStatus({
        title: "Snapshots",
        state: "released",
        createdOn: `Created on ${formatToDateOnly(
          results.latestReleasedSnapshot.createdOn
        )}`,
        version: `V${results.latestReleasedSnapshot.version}.0`,
      });
      return;
    }
    if (
      results.snapshotPendingApproval === null &&
      results.latestReleasedSnapshot === null
    ) {
      setSnapshotStatus({
        title: "Snapshots",
        state: "released",
        createdOn: "No snapshot data available",
        version: "",
      });
    }
  };

  useEffect(() => {
    setUuid(
      results.snapshotPendingApproval ? results.snapshotPendingApproval.id : ""
    );
    getSnapshotStatus(results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const createSnapshot = () => {
    try {
      createProductVariantSnapshot(productVariantId).then((data: Snapshot) => {
        notification.createNotification({
          type: NotificationTypes.Snackbar,
          message: "Snapshot creation started",
          severity: "success",
        });
        LogService.log("Create product variant snapshot result: ", data);
        setSnapshotStatus({
          title: "Snapshot creation in progress",
          state: "incomplete",
          createdOn: `Requested on ${formatToDateOnly(data.createdOn)}`,
          version: `V${data.version}.0`,
        });
        fetchResults(productVariantId);
        setSnapshotButtonDisabled(true);
      });
    } catch (e) {
      LogService.log("Create product variant snapshot failed: ", e);
    }
  };

  const approveSnapshot = () => {
    try {
      updateProductVariantSnapshot(
        productVariantId,
        "approve",
        uuid,
        results.ver
      ).then((result: Snapshot) => {
        notification.createNotification({
          type: NotificationTypes.Snackbar,
          message: "Snapshot approved",
          severity: "success",
        });
        setSnapshotStatus({
          title: "Snapshots",
          state: "released",
          createdOn: `Created on ${formatToDateOnly(result.createdOn)}`,
          version: `V${results.snapshotPendingApproval.version}.0`,
        });
        fetchResults(productVariantId);
      });
    } catch (e) {
      LogService.log("Approve product variant snapshot failed: ", e);
    }
  };

  const rejectSnapshot = () => {
    try {
      updateProductVariantSnapshot(
        productVariantId,
        "reject",
        uuid,
        results.ver
      ).then(() => {
        notification.createNotification({
          type: NotificationTypes.Snackbar,
          message: "Snapshot rejected",
          severity: "success",
        });
        setSnapshotStatus({
          title: "Snapshots",
          state: "released",
          createdOn: `Created on ${formatToDateOnly(
            results.latestReleasedSnapshot.createdOn
          )}`,
        });
        fetchResults(productVariantId);
      });
    } catch (e) {
      LogService.log("Reject product variant snapshot failed: ", e);
    }
  };

  const createSnapshotButton = () => {
    if (
      results &&
      !results.snapshotPendingApproval &&
      results.incompleteSnapshots.length === 0
    ) {
      return (
        <PermissionRequired
          hidden
          permissionKeys={[
            readProductLibrary,
            writeProductLibrary,
            createAuthentifieldSnapshot,
          ]}
        >
          <WideButton
            disabled={snapshotButtonDisabled}
            onClick={() => createSnapshot()}
          >
            <Typography variant="h5">Create new snapshot</Typography>
          </WideButton>
        </PermissionRequired>
      );
    }
    return null;
  };

  const approveOrRejectSnapshotButtons = () => {
    if (results && results.snapshotPendingApproval) {
      return (
        <PermissionRequired
          hidden
          permissionKeys={[writeLibraryPlanningApproval, readProductLibrary]}
        >
          <Button onClick={() => approveSnapshot()}>
            <Typography variant="h5">Approve</Typography>
          </Button>
          <Button variant="outlined" onClick={() => rejectSnapshot()}>
            <Typography variant="h5">Reject</Typography>
          </Button>
        </PermissionRequired>
      );
    }
    return null;
  };

  const getSnapshotContent = () => {
    if (
      snapshotStatus.state === "released" &&
      getActiveMeasurementCount() > 0
    ) {
      return createSnapshotButton();
    }
    if (snapshotStatus.state === "pending") {
      return approveOrRejectSnapshotButtons();
    }
    return null;
  };

  return (
    <Container>
      <TitleBox>
        <Typography variant="h4">{snapshotStatus.title}</Typography>
      </TitleBox>
      <InnerContainer>
        <TextContainer>
          <Typography variant="body1">{snapshotStatus.createdOn}</Typography>
          <Typography variant="body1">{snapshotStatus.version}</Typography>
        </TextContainer>
        <ButtonBox>{getSnapshotContent()}</ButtonBox>
      </InnerContainer>
    </Container>
  );
};

export default SnapshotComponent;
