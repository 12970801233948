import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  DrugAuthenticationService,
  HistoryService,
  StoredMeasurementHistoryItem,
  useError,
  useHttpErrorReader,
} from "lib-core";

import { AxiosError } from "axios";
import DetailsModal from "./DetailsModal";
import HistoryDetailsContent from "./HistoryDetailsContent";
import { ConfirmationParameters, NoteParameters } from "./history-types";

interface HistoryDetailsProps {
  id: string;
  measurerUsername: string;
  resetMeasurementHistory: () => void;
}

const HistoryDetails: (props: HistoryDetailsProps) => JSX.Element = ({
  id,
  measurerUsername,
  resetMeasurementHistory,
}) => {
  const [storedItem, setStoredItem] = useState<StoredMeasurementHistoryItem>();

  const { handleErrors } = useError();
  const errorReader = useHttpErrorReader();

  useEffect(() => {
    const getUserMeasurement = async () => {
      try {
        const { getMeasurementHistoryItemWithUserContent } = HistoryService;
        const historyItem = await getMeasurementHistoryItemWithUserContent(
          measurerUsername,
          id
        );
        setStoredItem(historyItem);
      } catch (error) {
        const { response: errorResponse } = error as AxiosError;
        if (errorResponse) {
          const { data } = errorResponse;
          const customHandling = errorReader(errorResponse);

          handleErrors({ error: data, customHandling });
        }
      }
    };

    if (!storedItem) {
      getUserMeasurement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, measurerUsername, storedItem]);

  const history = useHistory();

  const closeModal = () => {
    history.push("/history");
  };

  const confirmAuth = async (params: ConfirmationParameters) => {
    const { measurementId, result, ver } = params;
    await DrugAuthenticationService.updateAuthentication(
      result,
      measurementId,
      ver
    ).catch((e) => {
      if (e.response) {
        const { data: error } = e.response;

        handleErrors({
          error,
          customHandling: errorReader(e.response),
        });
      }
    });

    resetMeasurementHistory();
    setStoredItem(undefined);
  };

  const storeNote = async (params: NoteParameters) => {
    try {
      const { measurementId, note, userId } = params;

      await HistoryService.addMeasurementHistoryNote(
        userId,
        measurementId,
        note
      );

      resetMeasurementHistory();
      setStoredItem(undefined);
    } catch (error) {
      const { response: errorResponse } = error as AxiosError;
      if (errorResponse) {
        const { data: error } = errorResponse;
        const customHandling = errorReader(errorResponse);
        handleErrors({
          error,
          customHandling,
        });
      }
    }
  };

  if (storedItem) {
    return (
      <DetailsModal isOpen handleClose={closeModal}>
        <HistoryDetailsContent
          closeModal={closeModal}
          confirmAuth={confirmAuth}
          content={storedItem}
          storeNote={storeNote}
        />
      </DetailsModal>
    );
  }

  return <></>;
};

export default HistoryDetails;
