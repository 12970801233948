import React, { useState } from "react";
import styled from "styled-components";
import { default as MuiTextField } from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { default as MuiStack } from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Note, PermissionKeys, usePermissionChecking } from "lib-core";
import DetailsViewNoteItem from "./DetailsViewNoteItem";

interface DetailsViewNotesProps {
  notes: Note[];
  saveNote: (message: string) => void;
}

const Container = styled.div`
  grid-area: notes;
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Headline = styled(Typography)`
  margin-bottom: 14px;
`;

const TextField = styled(MuiTextField)`
  width: 100%;
`;

const Stack = styled(MuiStack)`
  align-items: flex-start;
`;

const NoteItems = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 0 0;
  margin-right: -12px;
  padding-right: 12px;
`;

const { writeMeasurementNote } = PermissionKeys;

const DetailsViewNotes: (props: DetailsViewNotesProps) => JSX.Element = ({
  notes,
  saveNote,
}) => {
  const [message, setMessage] = useState("");
  const requirePermission = usePermissionChecking();

  const disableItNote = () => {
    return !requirePermission([writeMeasurementNote]);
  };

  const disableItButton = () => {
    return message.length < 1;
  };

  const saveMessage = () => {
    saveNote(message);
  };

  const updateMessage = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(ev.target.value);
  };

  const numberOfNotes = notes.length;

  return (
    <Container>
      <Stack spacing={1}>
        <Headline variant="h3">Notes ({numberOfNotes})</Headline>
        <TextField
          disabled={disableItNote()}
          id="outlined-multiline-static"
          multiline
          onChange={updateMessage}
          rows={3}
          placeholder="What's on your mind?"
        />
        <Button
          disabled={disableItButton()}
          fullWidth={false}
          onClick={saveMessage}
          variant="outlined"
        >
          Add Note
        </Button>
      </Stack>
      <NoteItems>
        {notes.map((note: Note) => (
          <DetailsViewNoteItem key={note.id + note.createdOn} item={note} />
        ))}
      </NoteItems>
    </Container>
  );
};

export default DetailsViewNotes;
