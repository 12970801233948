import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  createFilter,
  createAutocompleteFilter,
  DataFilter,
  DataTable,
  FilterTypes,
  Hover,
  KeyValueParams,
  PermissionKeys,
  StoredMeasurementHistoryItem,
  TableSnapShot,
  usePermissionChecking,
} from "lib-core";
import { Button } from "@mui/material";
import Page from "../components/Layout/Page";
import { PageContent, PageSidebar } from "../components/Layout/PageContent";
import Header from "../components/Layout/Header";
import useMeasurementHistory, {
  useMeasurementUniqueValues,
} from "./history-hook";
import HistoryDetails from "./HistoryDetails";
import { downloadCsv } from "../shared/csv-helpers";

const historyCsvRowsLimit = 1000;

const { readMeasurementDetails, readMeasurementHistory, readWebPortal } =
  PermissionKeys;

const filterValue = (filterValues: KeyValueParams, filterName: string) =>
  filterValues.find((filter) => filter.key === filterName)?.value;

const transformUsernameInParams = (
  params: KeyValueParams,
  usernameMapping: Record<string, string> | undefined
) => {
  return params.map((param) => {
    const { key, value } = param;
    if (key === "username") {
      return {
        ...param,
        value: usernameMapping?.[value] ?? "",
      };
    }
    return param;
  });
};

const HistoryHomeView: () => JSX.Element = () => {
  const {
    measurementHistory,
    headers,
    fetchHistory,
    pagination,
    exportCsvMeasurementHistory,
    loading,
  } = useMeasurementHistory();

  const history = useHistory();
  const { search } = useLocation();
  const [perPage, setPerPage] = useState(10);
  const [filterOptionRefreshKey, setFilterOptionRefreshKey] = useState(0);
  const [rawFilterValues, setFilterValues] = useState<KeyValueParams>([]);

  const { filterOptions } = useMeasurementUniqueValues(filterOptionRefreshKey);

  const filterValues = useMemo(
    () =>
      transformUsernameInParams(
        rawFilterValues,
        filterOptions?.usernameMapping
      ),
    [rawFilterValues, filterOptions]
  );

  const requirePermission = usePermissionChecking();

  const openDetailsPermission = requirePermission([readMeasurementDetails]);
  const exportMCsvPermission = requirePermission([readMeasurementHistory]);
  const allowReadMeasurementHistory = requirePermission([
    readMeasurementHistory,
    readWebPortal,
  ]);

  useEffect(() => {
    if (allowReadMeasurementHistory === false) {
      history.replace("/error-403");
    }
  }, [allowReadMeasurementHistory, history]);

  const readSearchParams = () => {
    const params = new URLSearchParams(search);
    return Object.fromEntries(params);
  };

  const redirectToModal = <T,>(data: T, snapshot: TableSnapShot) => {
    // eslint-disable-next-line prettier/prettier
    const {
      id,
      measurerUsername,
      // eslint-disable-next-line prettier/prettier
    } = data as unknown as StoredMeasurementHistoryItem;

    const { rowsOnPage, page, sorting } = snapshot;

    const queryString = new URLSearchParams({
      id,
      measurerUsername,
      rowsOnPage,
      page,
      sorting: sorting.join(","),
    }).toString();

    history.push(`?${queryString}&openDetails=true`);
  };

  const onFilterUpdate = (params: KeyValueParams) => {
    fetchHistory(
      0,
      perPage,
      transformUsernameInParams(params, filterOptions?.usernameMapping)
    );
    setFilterValues(params);
  };

  const params = readSearchParams();

  const openDetails = !!params.openDetails && openDetailsPermission;

  useEffect(() => {
    // After user may edit status of a measurement, the filter options may change and we
    // need to reload the filter options again
    setFilterOptionRefreshKey((prev) => prev + 1);
  }, [openDetails]);

  const filters = useMemo(
    () => [
      createAutocompleteFilter({
        name: "globalBrand",
        placeholder: "Product",
        options: filterOptions?.metadataValues?.globalBrand ?? [],
        value: filterValue(filterValues, "globalBrand"),
      }),
      createAutocompleteFilter({
        name: "batchNumber",
        placeholder: "Batch number",
        options: filterOptions?.metadataValues?.batchNumber ?? [],
        value: filterValue(filterValues, "batchNumber"),
      }),
      createAutocompleteFilter({
        name: "caseNumber",
        placeholder: "Case number",
        options: filterOptions?.metadataValues?.caseNumber ?? [],
        value: filterValue(filterValues, "caseNumber"),
      }),
      createAutocompleteFilter({
        name: "authResult",
        placeholder: "Result",
        options: filterOptions?.metadataValues?.authResult ?? [],
        value: filterValue(filterValues, "authResult"),
      }),
      createAutocompleteFilter({
        name: "username",
        placeholder: "Measurer",
        // User won't be able to read the random string as username
        // so we use preferred username, which is email address as the value displayed
        options: filterOptions?.values?.measurerPreferredUsername ?? [],
        value: filterValue(filterValues, "measurerPreferredUsername"),
      }),
      createAutocompleteFilter({
        name: "deviceSerialNumber",
        placeholder: "Sensor",
        options: filterOptions?.values?.deviceSerialNumber ?? [],
        value: filterValue(filterValues, "deviceSerialNumber"),
      }),
      createFilter(
        "startTime",
        "From time",
        FilterTypes.DateTime,
        filterValue(filterValues, "startTime")
      ),
    ],
    [filterOptions, filterValues]
  );

  const resetMeasurementHistory = () => {
    // eslint-disable-next-line prettier/prettier
    const page = params.page as unknown as number;
    // eslint-disable-next-line prettier/prettier
    const perPage = params.perPage as unknown as number;
    fetchHistory(page, perPage);
  };

  const exportCSV = async () => {
    const csv = await exportCsvMeasurementHistory(
      transformUsernameInParams(filterValues, filterOptions?.usernameMapping)
    );
    const url = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
    const fileName = "measurements.csv";

    downloadCsv(fileName, url);
  };

  const hoverTooltipText = (rowsTotal: number | undefined) => {
    if (!rowsTotal || rowsTotal <= historyCsvRowsLimit) {
      return "";
    }
    return `Rows in file are limited to ${historyCsvRowsLimit} rows`;
  };

  const exportRowsBtnText = (rowsTotal: number | undefined) => {
    let rows = "";
    if (!rowsTotal) {
      rows = "-";
    } else if (rowsTotal <= historyCsvRowsLimit) {
      rows = rowsTotal.toString();
    } else {
      rows = historyCsvRowsLimit.toString();
    }
    return `Export CSV with ${rows} results`;
  };

  const disableCsvExport = () => !pagination || !exportMCsvPermission;

  return (
    <>
      <Header title="Measurement History">
        <Hover tooltipText={hoverTooltipText(pagination?.total)}>
          <Button
            data-testid="export-csv-button"
            disabled={disableCsvExport()}
            onClick={exportCSV}
            variant="outlined"
          >
            {exportRowsBtnText(pagination?.total)}
          </Button>
        </Hover>
      </Header>
      <Page>
        {openDetails && (
          <HistoryDetails
            id={params.id as string}
            measurerUsername={params.measurerUsername as string}
            resetMeasurementHistory={resetMeasurementHistory}
          />
        )}
        <PageSidebar>
          <DataFilter delay={500} filters={filters} onChange={onFilterUpdate} />
        </PageSidebar>
        <PageContent>
          <DataTable
            headers={headers}
            id="measurement-history-table"
            onClick={openDetailsPermission ? redirectToModal : undefined}
            loading={loading}
            rows={measurementHistory}
            pagination={pagination}
            onPageChange={(page: number, perPage: number) => {
              fetchHistory(page, perPage, filterValues);
            }}
            rowsPerPageOptions={[10, 50, 100, 200]}
            onPerPageChange={setPerPage}
            filterValues={filterValues}
          />
        </PageContent>
      </Page>
    </>
  );
};
export default HistoryHomeView;
