import {
  ProductVariantDetail,
  ProductVariantMeasurementSessionResponse,
} from "../types/contracts/Product";
import { MeasureMode } from "../types/types-measurement";
import { ProductVariant } from "../types/types-product";

const MEASUREMENT_SESSION_TOKEN = "8b305ef1-5df6-4ffa-b2fd-f1a2f80b4e29";
const MEASUREMENT_SESSION_TOTAL_NUMBER_OF_INTENDED_MEASUREMENTS = 2;

const mockProducts: ProductVariant[] = [
  {
    id: 74,
    name: "Ibuhexal FCT 600 MG OutPP",
    protocolName: "DS2-0-default",
    propertyNames: [
      "authResult",
      "batchNumber",
      "expiryDate",
      "manufacturingDate",
      "manufacturingSite",
      "productVariant",
      "referenceMaterialId",
      "referenceMaterialMeasurementId",
    ],
    createdOn: "2022-09-06T16:29:47Z",
    modifiedOn: "2022-09-15T06:42:34Z",
    ver: "616585",
    samples: [211],
    dataSets: [124],
    metadata: {
      sampleApprovalStatus: {
        draft: [],
        inApproval: [],
        released: [211],
        inDecommission: [],
        decommissioned: [],
      },
      dataSetApprovalStatus: {
        draft: [],
        inApproval: [],
        released: [124],
        inDecommission: [],
        decommissioned: [],
      },
      snapshotApprovalStatus: {
        draft: [],
        inApproval: [],
        released: ["a8ec8940-2e01-11ed-b372-227dd40ad592"],
        inDecommission: [],
        decommissioned: [],
      },
      globalBrand: "Ibuhexal",
      localBrands: ["Ibuhexal"],
      dosageForm: "FCT",
      dosageStrength: "600",
      dosageUnit: "MG",
      measureMode: MeasureMode.OutPP,
      dummyVariant: false,
      productVariant: "Ibuhexal FCT 600 MG OutPP",
      modelsInTraining: [
        {
          runId: "4b59c91a064b40849bdb2882de438a8d",
          snapshot: "a8ec8940-2e01-11ed-b372-227dd40ad592",
        },
      ],
      pendingChanges: {
        protocolName: null,
        nMeasurementsPerBatch: null,
      },
    },
  },
];

const getDetailById = (): ProductVariantDetail => ({
  id: 74,
  name: "Ibuhexal FCT 600 MG OutPP",
  protocolName: "DS2-0-default",
  propertyNames: [
    "authResult",
    "batchNumber",
    "expiryDate",
    "manufacturingDate",
    "manufacturingSite",
    "productVariant",
    "referenceMaterialId",
    "referenceMaterialMeasurementId",
  ],
  createdOn: "2022-09-06T16:29:47Z",
  modifiedOn: "2022-09-15T06:42:34Z",
  ver: "616585",
  samples: [
    {
      description: "1_OutPP",
      isWhiteReference: false,
      propertyValues: {
        referenceMaterialId: 1,
        referenceMaterialMeasurementId: "1_OutPP",
        manufacturingSite: "Ibuhexal manufacturing site",
        batchNumber: "B00000",
        manufacturingDate: "01/2021",
        expiryDate: "02/2023",
        productVariant: "Ibuhexal FCT 600 MG OutPP",
      },
      id: 211,
      createdOn: "2022-09-06T16:29:48Z",
      modifiedOn: "2022-09-06T16:29:48Z",
    },
  ],
  dataSets: [
    {
      protocolName: "DS2-0-default",
      metadata: {
        batchNumber: "B00000",
        materialNumber: 1,
        referenceMaterialId: 1,
        referenceMaterialMeasurementId: "1_OutPP",
        manufacturingSite: "Ibuhexal manufacturing site",
        globalBrand: "Ibuhexal",
        localBrands: ["Ibuhexal"],
        isActive: true,
        productVariant: "Ibuhexal FCT 600 MG OutPP",
        nMeasurementsPerBatch: 32,
        measurementSession: null,
      },
      name: "1_OutPP Ibuhexal manufacturing site",
      id: 124,
      createdOn: "2022-09-06T16:29:47Z",
      modifiedOn: "2022-09-06T16:29:47Z",
      ver: "613384",
    },
  ],
  metadata: {
    sampleApprovalStatus: {
      draft: [],
      inApproval: [],
      released: [211],
      inDecommission: [],
      decommissioned: [],
    },
    dataSetApprovalStatus: {
      draft: [],
      inApproval: [],
      released: [124],
      inDecommission: [],
      decommissioned: [],
    },
    snapshotApprovalStatus: {
      draft: [],
      inApproval: [],
      released: ["a8ec8940-2e01-11ed-b372-227dd40ad592"],
      inDecommission: [],
      decommissioned: [],
    },
    globalBrand: "Ibuhexal",
    localBrands: ["Ibuhexal"],
    dosageForm: "FCT",
    dosageStrength: "600",
    dosageUnit: "MG",
    measureMode: MeasureMode.OutPP,
    dummyVariant: false,
    productVariant: "Ibuhexal FCT 600 MG OutPP",
    modelsInTraining: [
      {
        runId: "4b59c91a064b40849bdb2882de438a8d",
        snapshot: "a8ec8940-2e01-11ed-b372-227dd40ad592",
      },
    ],
    pendingChanges: {
      protocolName: null,
      nMeasurementsPerBatch: null,
    },
  },
});

const getStartMeasurementSession = (
  id: number,
  totalNumberOfIntendedMeasurements = MEASUREMENT_SESSION_TOTAL_NUMBER_OF_INTENDED_MEASUREMENTS
): ProductVariantMeasurementSessionResponse => {
  return {
    measurementSessionId: id,
    measurementSessionToken: MEASUREMENT_SESSION_TOKEN,
    totalNumberOfIntendedMeasurements,
    closed: false,
    expiresOn: new Date().toISOString(),
  };
};

const MockedProductVariant = {
  getStartMeasurementSession,
  getDetailById,
  mockProducts,
};

export default MockedProductVariant;
