import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  ImportCSV,
  ProductService,
  ProductFlatlist as LibCoreProductFlatlist,
  ProductNameFormatted,
  KeyValueParams,
  ProductVariant,
  DataFilter,
  createFilter,
  PermissionKeys,
  usePermissionChecking,
  PermissionRequired,
  FilterTypes,
  Filter,
  MeasureMode,
} from "lib-core";
import { useHistory } from "react-router-dom";
import {
  Button,
  Chip,
  Divider as MuiDivider,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Page from "../components/Layout/Page";
import Header from "../components/Layout/Header";
import GuideStep from "./GuideStep";
import TrainingTab from "./TrainingTab";
import PlanningTab from "./PlanningTab";
import ModelsTab from "./ModelsTab";
import ResultsTab from "./ResultsTab";
import { downloadCsv } from "../shared/csv-helpers";
import useProductVariant from "./productVariant-hook";
import {
  PageContent,
  PageSidebarContained,
} from "../components/Layout/PageContent";

const ActionButtons = styled.nav`
  display: flex;
`;

const ProductName = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const GuideContentPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 60px;
  flex: 1 0 0;
`;

const GuideSteps = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
`;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin: 5px 0 24px;
`;

const Divider = styled(MuiDivider)`
  margin: 5px -30px 16px;
`;

const ExportCSV = styled(Button)`
  margin-right: 15px;
`;

const ProductFlatlist = styled(LibCoreProductFlatlist)`
  padding: 0;
  margin: 0 -16px;

  .Mui-selected {
    &,
    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => theme.palette.primary.main};
      background: ${({ theme }) => theme.palette.background.default};
    }
  }

  &.empty-list {
    color: ${({ theme }) => theme.palette.brandGray.dark};
    text-align: center;
    margin-top: 20px;
  }
`;

const LibraryHomeView: () => JSX.Element = () => {
  const history = useHistory();
  const {
    readProductLibrary,
    createProductLibrary,
    readAuthentifieldProductLibraryTraining,
    readAuthentifieldProductLibraryPlanning,
    readAuthentifieldProductLibraryModels,
    writeLibraryPlanningRequest,
    writeLibraryPlanningApproval,
    createAuthentifieldSnapshot,
    readWebPortal,
    readResults,
    readSnapshots,
  } = PermissionKeys;
  const requirePermission = usePermissionChecking();
  const allowLibraryManagement = requirePermission([
    readProductLibrary,
    readWebPortal,
  ]);
  const allowTraining = requirePermission([
    readAuthentifieldProductLibraryTraining,
  ]);
  const allowPlanning = requirePermission([
    readAuthentifieldProductLibraryPlanning,
  ]);
  const allowModels = requirePermission([
    readAuthentifieldProductLibraryModels,
  ]);
  const allowResults = requirePermission([readResults, readSnapshots]);
  const [subPage, setSubPage] = useState<string>(
    allowPlanning ? "Planning" : "Results"
  );

  const [selectedProduct, setSelectedProduct] = useState<ProductVariant>();
  const [filterParams, setFilterParams] = useState<KeyValueParams>([]);

  const subPages = ["Planning", "Results", "Training", "Models"];

  const setAllowedSubPage = () => {
    const allowedSubPages = [...subPages];
    if (!allowPlanning) {
      allowedSubPages.splice(allowedSubPages.indexOf("Planning"), 1);
    }
    if (!allowResults) {
      allowedSubPages.splice(allowedSubPages.indexOf("Results"), 1);
    }
    if (!allowTraining) {
      allowedSubPages.splice(allowedSubPages.indexOf("Training"), 1);
    }
    if (!allowModels) {
      allowedSubPages.splice(allowedSubPages.indexOf("Models"), 1);
    }

    if (allowedSubPages.length === 0) {
      history.replace("/error-403");
    } else {
      const firstSubPage = allowedSubPages[0];
      if (firstSubPage) {
        setSubPage(firstSubPage);
      }
    }
  };

  useEffect(() => {
    if (allowLibraryManagement === false) {
      history.replace("/error-403");
    }
  }, [allowLibraryManagement, history]);

  const filters: Filter[] = [
    createFilter("query", "Product", FilterTypes.String),
    createFilter("dosageForm", "Dosage form", FilterTypes.String),
    createFilter("dosageStrength", "Dosage strength", FilterTypes.String),
    {
      name: "measureMode",
      placeholder: "Measurement mode",
      type: FilterTypes.Autocomplete,
      value: "",
      options: [MeasureMode.InPP, MeasureMode.OutPP],
    },
  ];

  const [page, setPage] = useState(0);

  const variantPagingData = useMemo(() => ({ page, perPage: 100 }), [page]);

  const {
    fetchProductVariantsList,
    productVariantsList,
    fetchProductVariantById,
    productVariant,
    productVariantsTotal,
  } = useProductVariant();

  const descriptions = {
    review:
      "Selecting a product variant in the sidebar shows information about the variant.",
    import:
      "Add products to the library using the Import CSV function in the top right corner.",
    search:
      "Each product variant is identified by four attributes you can use to filter the variant list in the left sidebar.",
    manage:
      "Selecting a product variant in the sidebar shows managment options for the variant.",
  };

  const groupedDescriptions = {
    searchReview: { search: descriptions.search, review: descriptions.review },
    importSearchManage: {
      import: descriptions.import,
      search: descriptions.search,
      manage: descriptions.manage,
    },
    searchManage: { search: descriptions.search, manage: descriptions.manage },
  };

  // return descriptions based on what permissions the user has
  const getDescriptions = () => {
    if (requirePermission([writeLibraryPlanningRequest])) {
      return Object.entries(groupedDescriptions.importSearchManage);
    }
    if (requirePermission([writeLibraryPlanningApproval])) {
      return Object.entries(groupedDescriptions.searchManage);
    }
    if (requirePermission([createAuthentifieldSnapshot])) {
      return Object.entries(groupedDescriptions.searchManage);
    }
    // read-only baseline
    return Object.entries(groupedDescriptions.searchReview);
  };

  useEffect(() => {
    fetchProductVariantsList(variantPagingData, filterParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams, variantPagingData]);

  useEffect(() => {
    setAllowedSubPage();
    setSelectedProduct(productVariant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowPlanning, productVariant]);

  const productCallback = (product: ProductVariant) => {
    setAllowedSubPage();
    setSelectedProduct(product);
  };

  const onFilterUpdate = (params: KeyValueParams) => {
    setFilterParams(params);
    setPage(0);
  };

  const getProductVariant = (variantId: number) => {
    fetchProductVariantById(variantId);
  };

  const exportCSV = async () => {
    const csv = await ProductService.getExportCSV();
    const url = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;

    const fileName = "products.csv";
    downloadCsv(fileName, url);
  };

  const productIsDummy = selectedProduct?.metadata.dummyVariant === true;

  const onTabChange = (_event: React.SyntheticEvent, tabValue: string) =>
    setSubPage(tabValue);

  const disableTabForDummy = (tabName: string) => {
    if (productIsDummy) {
      if (tabName === "Models" || tabName === "Training") {
        return true;
      }
    }

    return false;
  };

  const disableTabForPermission = (tabName: string) => {
    if (tabName === "Models" && !allowModels) {
      return true;
    }
    if (tabName === "Planning" && !allowPlanning) {
      return true;
    }
    if (tabName === "Training" && !allowTraining) {
      return true;
    }
    if (tabName === "Results" && !allowResults) {
      return true;
    }

    return false;
  };

  const tableSwitch = (subPage: string) => {
    if (!selectedProduct) {
      return null;
    }
    switch (subPage) {
      case "Planning":
        return (
          <PlanningTab
            productVariant={selectedProduct}
            getProductVariant={getProductVariant}
          />
        );
      case "Results":
        return <ResultsTab productVariant={selectedProduct} />;
      case "Training":
        return <TrainingTab productVariantId={selectedProduct.id} />;
      case "Models":
        return <ModelsTab productVariant={selectedProduct} />;
      default:
        return "";
    }
  };

  return (
    <>
      <Header title="Library Management">
        <ActionButtons>
          <PermissionRequired
            permissionKeys={[readProductLibrary, createProductLibrary]}
          >
            <ExportCSV
              data-testid="export-csv-button"
              onClick={exportCSV}
              variant="outlined"
            >
              Export CSV
            </ExportCSV>
            <ImportCSV
              refreshData={() =>
                fetchProductVariantsList(variantPagingData, filterParams)
              }
              service={ProductService.importLibraryCSV}
            />
          </PermissionRequired>
        </ActionButtons>
      </Header>
      <Page>
        <PageSidebarContained>
          <DataFilter delay={500} filters={filters} onChange={onFilterUpdate} />
          <Divider />
          <ProductFlatlist
            count={productVariantsTotal}
            page={page}
            onPageChange={setPage}
            productvariants={productVariantsList ?? []}
            productCallback={productCallback}
          />
        </PageSidebarContained>
        {selectedProduct ? (
          <PageContent>
            <HeaderContainer>
              <ProductName variant="h3">
                <ProductNameFormatted product={selectedProduct} />
                {selectedProduct.metadata.dummyVariant && (
                  <Chip
                    label="Dummy"
                    color="error"
                    size="small"
                    variant="outlined"
                  />
                )}
              </ProductName>
              <Tabs value={subPage} onChange={onTabChange}>
                {subPages.map((element: string) => (
                  <Tab
                    key={element}
                    label={element}
                    value={element}
                    disabled={
                      disableTabForDummy(element) ||
                      disableTabForPermission(element)
                    }
                  />
                ))}
              </Tabs>
            </HeaderContainer>
            {tableSwitch(subPage)}
          </PageContent>
        ) : (
          <GuideContentPanel>
            <GuideSteps>
              {getDescriptions().map(([action, description], key) => (
                <GuideStep key={key} number={key + 1} title={action}>
                  {description}
                </GuideStep>
              ))}
            </GuideSteps>
          </GuideContentPanel>
        )}
      </Page>
    </>
  );
};

export default LibraryHomeView;
