import { useState } from "react";
import {
  DeviceService,
  KeyValueParams,
  Device,
  TableDataArray,
  PagingData,
  Sorting,
  useError,
  PagedResult,
  useHttpErrorReader,
  PermissionKeys,
  usePermissionChecking,
} from "lib-core";
import { mapDevices } from "./device-helpers";

type DeviceData = {
  devices: TableDataArray<Device>;
  fetchAndSetStateDevices(
    page: number,
    perPage: number,
    filters?: KeyValueParams,
    sorting?: Sorting[]
  ): void;
  fetchDevices(
    page: number,
    perPage: number,
    filters?: KeyValueParams,
    sorting?: Sorting[]
  ): Promise<PagedResult<Device>>;
  pagination: PagingData | undefined;
  loading: boolean;
};

const useDevices = (): DeviceData => {
  const [devices, setDevices] = useState<TableDataArray<Device>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PagingData>();

  const { readDeviceUpdate, writeDeviceUpdate } = PermissionKeys;
  const requirePermission = usePermissionChecking();
  const allowStatusUpdating = requirePermission([
    readDeviceUpdate,
    writeDeviceUpdate,
  ]);

  const { handleErrors } = useError();
  const errorReader = useHttpErrorReader();

  // Fetch devices and return promise
  const fetchDevices = (
    page: number,
    perPage: number,
    filters: KeyValueParams,
    sorting: Sorting[]
  ) => {
    // Remove empty filters and reduce to object. Return request promise
    // This is handy when we need to fetch raw list i.e. csv output
    const metadata = (filters || [])
      .filter((p) => p.value !== "")
      .reduce((acc, filter) => {
        const { key, value } = filter;
        if (key) {
          return { ...acc, [key]: value };
        }
        return acc;
      }, {});

    return DeviceService.getPagedDevices(
      { page, perPage },
      { ...metadata, expanded: "true" },
      sorting
    );
  };

  // Fetch paged results and set hook state from results
  // Used in normal listing and paging view in web app
  const fetchAndSetStateDevices = async (
    page: number,
    perPage: number,
    filters: KeyValueParams,
    sorting: Sorting[]
  ) => {
    setLoading(true);

    const pagedDevicesData = await fetchDevices(page, perPage, filters, sorting)
      .then((pagedDevices) => {
        return pagedDevices
          ? {
              entities: pagedDevices.entities,
              paging: { page, perPage, total: pagedDevices?.paging.total },
            }
          : {
              entities: [],
              paging: { page: 1, perPage, total: 0 },
            };
      })
      .catch((e) => {
        if (e.response) {
          const { data: error } = e.response;

          handleErrors({
            error,
            customHandling: errorReader(e.response),
          });
        }
      });

    if (pagedDevicesData) {
      const mapped = mapDevices(pagedDevicesData.entities, allowStatusUpdating);

      setDevices(mapped);
      setPagination(pagedDevicesData.paging);
    }
    setLoading(false);
  };

  return {
    devices,
    fetchAndSetStateDevices,
    fetchDevices,
    pagination,
    loading,
  };
};

export default useDevices;
