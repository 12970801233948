import PredictionValue from "../../types/contracts/PredictionValue";
import StoredMeasurementHistoryItem from "../../types/contracts/StoredMeasurementHistoryItem";
import { ResultData } from "../../types/types-measurement-history";

const measurementHistoryToResultData = (
  historyItem: StoredMeasurementHistoryItem
): ResultData => {
  return {
    id: historyItem.id,
    batchNumber: historyItem.metadata.batchNumber,
    caseNumber: historyItem.metadata.caseNumber,
    comment: historyItem.description,
    measurementDate: historyItem.createdOn,
    dosage: `${historyItem.metadata.dosageForm}, ${
      historyItem.metadata.dosageStrength
    }${historyItem.metadata.dosageUnit.toLowerCase()}`,
    productName: historyItem.metadata.globalBrand,
    predictionValue:
      historyItem.metadata.authResult ??
      historyItem?.predictions[0]?.renderedValue ??
      PredictionValue.Invalid,
    measureMode: historyItem.metadata.measureMode,
  };
};

const HistoryMappingService = {
  measurementHistoryToResultData,
};

export default HistoryMappingService;
