import { AxiosError } from "axios";
import {
  NotificationTypes,
  ProductService,
  ResultsResponse,
  Snapshot,
  useError,
  useHttpErrorReader,
  useNotification,
} from "lib-core";
import { useState } from "react";
import { downloadCsv } from "../shared/csv-helpers";

type MeasurementHistoryData = {
  fetchResults(variantId: number): void;
  downloadManufacturingCsv(
    metadataUrl: string,
    manufacturingSite: string,
    index: number
  ): void;
  createProductVariantSnapshot(productVariantId: number): Promise<Snapshot>;
  updateProductVariantSnapshot(
    productVariantId: number,
    action: string,
    uuid: string,
    ver: string
  ): Promise<Snapshot>;
  loadingResults: boolean;
  results: ResultsResponse;
  error: unknown | undefined;
  csvIndex: number | undefined;
  csvRequestBusy: boolean;
};

const useProductVariantResults = (): MeasurementHistoryData => {
  const [loadingResults, setLoadingResults] = useState<boolean>(false);
  const [csvIndex, setCsvIndex] = useState<number | undefined>();
  const [csvRequestBusy, setCsvRequestBusy] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const { handleErrors } = useError();
  const errorReader = useHttpErrorReader();
  const [results, setResults] = useState<ResultsResponse>(Object());
  const notification = useNotification();

  const fetchResults = async (variantId: number) => {
    setLoadingResults(true);
    setError(undefined);
    try {
      const result = await ProductService.getProductVariantResults(variantId);
      setResults(result);
      setLoadingResults(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setLoadingResults(false);
      setError(err);
    }
    setLoadingResults(false);
    setError(undefined);
  };

  const downloadManufacturingCsv = async (
    metadataUrl: string,
    manufacturingSite: string,
    index: number | undefined
  ) => {
    setCsvIndex(index);
    setCsvRequestBusy(true);
    try {
      notification.createNotification({
        severity: "success",
        type: NotificationTypes.Snackbar,
        message:
          "CSV download started. The download will continue if you navigate to a different page on this website.",
      });
      const { getManufacturingMetadataCsv } = ProductService;
      const extManufacturingSiteCsv = await getManufacturingMetadataCsv(
        metadataUrl
      );
      const url = `data:text/csv;charset=utf-8,${encodeURI(
        extManufacturingSiteCsv
      )}`;
      const site = manufacturingSite.split(" ").join("_");
      const fileName = `result-${site}.csv`;
      downloadCsv(fileName, url);
      setCsvIndex(undefined);
      setCsvRequestBusy(false);
    } catch (err) {
      const { response: errorResponse } = err as AxiosError;

      if (errorResponse) {
        const { data: error } = errorResponse;
        handleErrors({
          error,
          customHandling: errorReader(errorResponse),
        });
      }
      setCsvIndex(undefined);
      setCsvRequestBusy(false);
    }
    setCsvIndex(undefined);
    setCsvRequestBusy(false);
  };

  const createProductVariantSnapshot = async (
    productVariantId: number
  ): Promise<Snapshot> => {
    const request = await ProductService.createProductVariantSnapshot(
      productVariantId
    );
    return request;
  };

  const updateProductVariantSnapshot = async (
    productVariantId: number,
    action: string,
    uuid: string,
    ver: string
  ): Promise<Snapshot> => {
    const request = await ProductService.updateProductVariantSnapshot(
      productVariantId,
      action,
      uuid,
      ver
    );
    return request;
  };

  return {
    fetchResults,
    downloadManufacturingCsv,
    createProductVariantSnapshot,
    updateProductVariantSnapshot,
    loadingResults,
    results,
    error,
    csvIndex,
    csvRequestBusy,
  };
};

export default useProductVariantResults;
