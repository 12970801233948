import React from "react";
import { Autocomplete, FilterOptionsState } from "@mui/material";
import { Filter } from "./datafilter-types";
import AutocompleteInput from "./AutocompleteInput";

interface AutocompleteFilterProps {
  filter: Filter;
  updateFilterList: (name: string, value: string) => void;
}

const AutocompleteFilter: (props: AutocompleteFilterProps) => JSX.Element = ({
  filter,
  updateFilterList,
}) => {
  const { name, placeholder, value, options } = filter;
  const filterOptions = (
    options: string[],
    state: FilterOptionsState<string>
  ): string[] => {
    const searchTerms = state.inputValue.toLowerCase().split(/\s/);
    return options.filter((label) => {
      const optionLower = label.toLowerCase();
      return searchTerms.every((term) => optionLower.indexOf(term) !== -1);
    });
  };

  return (
    <Autocomplete
      value={value}
      options={options ?? []}
      filterOptions={filterOptions}
      renderInput={(props) => (
        <AutocompleteInput {...props} placeholder={placeholder} name={name} />
      )}
      onChange={(_, value, reason) => {
        if (reason === "selectOption" && value) {
          updateFilterList(name, value);
        } else if (reason === "clear") {
          updateFilterList(name, "");
        }
      }}
    />
  );
};

export default AutocompleteFilter;
